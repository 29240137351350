export const meQuery = `
        query MeQuery{
            me{
            fullName
            username
            email
            isActive
            isSuperuser
            isStaff
            dateJoined
            profilePic
            createdByCompany{
                edges{
                node{
                    id
                    title
                }
                }
            }
            kind
            planSet{
                edges{
                node{
                    tier
                    status
                    stripeCustomerId
                    stripeSubscriptionId
                    id
                }
                }
            }
            }
        }

`;
export const contentQuery = `
        query contentStoreQuery($kind: Int
            $offset: Int
            $before: String
            $after: String
            $first: Int
            $last: Int
            $status: Boolean
            $url: String
            $title: String
            $category: String){
            contentstorePagination(kind: $kind
                offset: $offset
                before: $before
                after: $after
                first: $first
                last: $last
                status: $status
                title: $title
                url: $url
                category: $category){
            pageInfo{
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                }
            edges{
                node{
                    id
                    title
                    description
                    url
                    publishedDate
                    images
                    customImage
                    tags
                    isliked
                    islikedCount
                }
                cursor
            }
            }
        }
  `;

export const contentLibraryQuery = `
    query contentStoreQuery($kind: Int
        $offset: Int
        $before: String
        $after: String
        $first: Int
        $last: Int
        $status: Boolean
        $url: String
        $category: String){
        contentstoreLibraryPagination(kind: $kind
            offset: $offset
            before: $before
            after: $after
            first: $first
            last: $last
            status: $status
            url: $url
            category: $category){
        pageInfo{
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            }
        edges{
            node{
                id
                title
                description
                url
                publishedDate
                images
                customImage
                tags
                isliked
                islikedCount
            }
            cursor
        }
        }
    }
`;

export const videocontentQuery = `
    query QuerygetVideoContentStorepaginationQuery($kind: Int
        $offset: Int
        $before: String
        $after: String
        $first: Int
        $last: Int
        $status: Boolean
        $url: String
        $title: String
        $submitcategory: String) {
        videoContentstorePagination(kind: $kind
            offset: $offset
            before: $before
            after: $after
            first: $first
            last: $last
            status: $status
            url: $url
            title: $title
            submitcategory: $submitcategory){
        pageInfo{
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            }
        edges{
            node{
                id
                title
                keywords                
                url
                tags
                rank
                channelTitle
                description
                image
                customImage
                customUrl
                channelIcon
                videopublishedat
                likes
                views
                dislikes
                comments
                favcount
                channelId
                isliked        
                islikedCount
            }
            cursor
        }
        }
    }

`

export const videoLibraryQuery = `
    query QuerygetVideoContentstoreLibraryPaginationQuery($kind: Int
        $offset: Int
        $before: String
        $after: String
        $first: Int
        $last: Int
        $status: Boolean
        $url: String
        $title: String) {
            videoContentstoreLibraryPagination(kind: $kind
            offset: $offset
            before: $before
            after: $after
            first: $first
            last: $last
            status: $status
            url: $url
            title: $title){
        pageInfo{
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            }
        edges{
            node{
                id
                title
                keywords                
                url
                tags
                rank
                channelTitle
                description
                image
                customImage
                customUrl
                channelIcon
                videopublishedat
                likes
                views
                dislikes
                comments
                favcount
                channelId
                isliked        
                islikedCount
            }
            cursor
        }
        }
    }

`

export const getlistSearchTermsQuery = `
    query trendsPaginationQuery {
        trendsPagination {
        edges {
                node {
                    id
                    createdAt
                    keyword            
                }
                cursor
            }
        }
    }
`

export const getSearchTermsVideosQuery = `
    query trendsPaginationQuery($keyword: String, $after: String, $termid: String) {
        trendsPagination(keyword:$keyword, termid:$termid) {
        edges {
            node {
            videos(after:$after) {
                pageInfo{
                    hasNextPage
                    hasPreviousPage
                    endCursor
                    startCursor
                  }
                edges {
                    node {
                            id
                            title
                            keywords
                            isliked        
                            islikedCount
                            url
                            tags
                            rank
                            channelTitle
                            channelIcon
                            description
                            image
                            customImage
                            customUrl
                            videopublishedat
                            likes
                            views
                            dislikes
                            comments
                            favcount
                            channelId  
                        }
                    cursor
                }
            }
            }
        }
        }
    }
`