import * as React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

export default function PageBreadcrumbs(props) {
  return (
    <div role="presentation">  {/* onClick={handleClick} */}
      <Breadcrumbs aria-label="breadcrumb">
        {props.links?.map((val, indx) => {
          if (val.type === "link") {
            return <Link underline="hover" color="grey.400" href={val.url} fontSize={14} className="cursor-pointer">
              {val.title}
            </Link>
          } else {
            return <Typography key={indx} color="grey.600" fontSize={14}>
              {val.title}
            </Typography>
          }
        })}
      </Breadcrumbs>
    </div>
  )
}
