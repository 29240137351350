import React, {useState} from 'react'
import {Box, Typography, TextField, Stack, useMediaQuery} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import Icons from './shared/Icons/Icons'

type Props = {}

const ContactUs = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const matches = useMediaQuery('(min-width:600px)')
  return (
    <>
      <Box
        sx={{borderBottom: 0.3, borderColor: 'grey.300'}}
        py={3}
        px={3}
        className="relative fadein"
      >
        <Typography variant="h5" component="h3" fontWeight="bold">
          Contact us
        </Typography>
        {/* <div style={{}}> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{position: 'absolute', top: -110, right: 0}}
          className="settingstopwave"
        >
          <path
            fill="#4db6ac"
            fillOpacity="1"
            d="M0,96L80,128C160,160,320,224,480,240C640,256,800,224,960,176C1120,128,1280,64,1360,32L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
        {/* </div> */}
      </Box>
      <Box p={3} className="fadein">
        <Box className="relative">
          <form>
            <Stack direction="column" gap={4}>
              <TextField
                helperText=""
                multiline
                rows={4}
                id="demo-helper-text-aligned"
                label="Details"
                autoFocus
                sx={{mt: 1, width: '50%'}}
              />
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="large"
                sx={{textTransform: 'inherit', px: 8, width: '50%'}}
              >
                {loading ? 'Please wait...' : 'Submit'}
              </LoadingButton>
            </Stack>
          </form>
          {matches && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '15%',
                right: '6rem',
                zIndex: 2,
              }}
            >
              <Icons
                color="secondary"
                animprop="default"
                icontype="Userforcontact"
                animtype="noanim"
              />
            </div>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ContactUs
