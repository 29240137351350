import { getSearchTermsVideosQuery, getlistSearchTermsQuery, videocontentQuery } from "../api/graphql/Queries";
import { fetchQuery } from "../api/ContentQueries";
import { useState, useEffect } from "react";
import { MutateMutation } from "../api/ContentMutations";
import { ContentAddorUpdateMutation, TrendDumpMutation } from "../api/graphql/Mutations";
import { isEmpty } from "../utils/util";

export default function useTrendsVideoContentstore(relaycursor, filterobject) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [isopen, setIsopen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [statusmsg, setStatusmsg] = useState("")
    const [hasMore, setHasMore] = useState(false)
    const [contentdata, setContentdata] = useState<any>([])
    const [searchterms, setSearchterms] = useState<any>([])
    const [searchid, setSearchid] = useState<any>(null)


    useEffect(() => {
        setContentdata([])

        return () => {
            setContentdata([])
        }
    }, [filterobject.termid])

    const fetchTrendsQuery = (filterinput) => {
        setLoading(true)
        setError(false)
        
        try {
            fetchQuery(getSearchTermsVideosQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { trendsPagination: { edges } } = data;
                    const extractedVideos = edges.map(val => val.node.videos)[0]
                    const { edges: videoedges, pageInfo } = extractedVideos;
                    setHasMore(pageInfo.hasNextPage)
                    setContentdata((prevContentdata) => [...prevContentdata, ...videoedges])
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setLoading(false)
                setHasMore(false)

            })

        } catch {
            console.log("rejection error")
            setHasMore(false)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!filterobject.termid) return;
        const filterinput = {
            first: 8,
            after: relaycursor,
            ...filterobject
        }
        fetchTrendsQuery(filterinput);

        // .then((data:any) => {
        //     console.log(data)
        //     // if (data.recruiterFilterJobs) {
        //     //     setViewjobsdata(prevJobsdata => {
        //     //         return [...prevJobsdata, ...data.recruiterFilterJobs?.edges]
        //     //     })

        //     //     if (viewjobsdata && +viewjobsdata?.length < data.recruiterFilterJobs?.totalCount) {
        //     //         setHasMore(true)
        //     //     } else {
        //     //         setHasMore(false)
        //     //     }
        //     // }
        //     setLoading(false)
        // }).catch((e) => {
        //     setError(true)

        // });

        return () => {
            console.log("unmounting useTrendsVideoContentstore.")
        }
    }, [relaycursor, filterobject.termid])

    useEffect(() => {
        setLoading(true)
        setError(false)
        const filterinput = {
            first: 8,
            after: relaycursor,
            ...filterobject
        }
        try {
            fetchQuery(getlistSearchTermsQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { trendsPagination: { edges, pageInfo } } = data;
                    setSearchterms((prevContentdata) => [...prevContentdata, ...edges])
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setLoading(false)

            })

        } catch {
            console.log("rejection error")
            setLoading(false)
        }

        return () => {
            console.log("unmounting useContentstore.")
        }
    }, [])


    const fetchTrendsMutation = async (data: any) => {
        setContentdata([])
        setLoading(true)
        try {
            await MutateMutation(TrendDumpMutation, data).then((data) => {
                if (data !== 400) {
                    const { dumpTrends: { Status:{searchtermid} } } = data;
                    console.log(searchtermid)
                    const filterinput = {
                        first: 8,
                        after: "",
                        termid:searchtermid
                    }
                    fetchTrendsQuery(filterinput)
                }
            }).catch((e) => {
                setError(true)
                setLoading(false)

            })

        } catch {
            setStatusmsg("something went wrong!")
            setIsopen(true)
        }

    }

    const likeordislike = async (data: any) => {

        try {
            await MutateMutation(ContentAddorUpdateMutation, data).then((data) => {
                if (data !== 400) {
                    const { libraryGroupAction: { Status } } = data;
                    setStatusmsg("successfully done!")
                    setIsopen(true)
                }
            }).catch((e) => {
                setError(true)

            })

        } catch {
            setStatusmsg("something went wrong!")
            setIsopen(true)
        }

    }

    return { loading, error, setError, contentdata, searchid, fetchTrendsMutation, searchterms, likeordislike, hasMore, statusmsg, success, setSuccess, isopen, setIsopen }
}