export const CONTENT_ARTICLES = 'CONTENT_ARTICLES'
export const CONTENT_VIDEOS = 'CONTENT_VIDEOS'
export const CONTENT_TRENDS = 'CONTENT_TRENDS'
export const CONTENT_TOOLS = 'CONTENT_TOOLS'

export const LOGIN_MUTATION = 'LOGIN_MUTATION'


export const ALERT_SUCCESS = 'success'
export const ALERT_WARNING = 'warning'
export const ALERT_INFO = 'info'
export const ALERT_ERROR = 'error'

// Route constants
export const LOGIN_GATEWAY = '/auth/gateway'
export const ARTICLES_URL = '/content/articles'

// kinds

export const LIKEORDISLIKETYPE = {
    LIKESTOGGLE:1,
    TEXT:2,
    VIDEO:1
    
}

export const UPLOADFILEKIND = {
    PROFILE_PICTURE:1
}

// @choices
// class UploadKind:
//     class Meta:
//         PROFILE_PICTURE = (1, "Profile Picture")
//         RESUME = (2, "Resume")
//         COMPANY_INTRO_VIDEO = (3, "Company Intro Video")
//         OTHER_DOCS = (4, "Other Documents")
//         COMPANY_PROFILE_PICTURE = (5, "Company Profile Picture")
//         PROFILE_VIDEO = (6, "Profile Video")
//         JOB_VIDEO = (7, "Job Video")
        
// @choices
// class UploadStatus:
//     class Meta:
//         UPLOADING = (1, "Uploading")
//         UPLOADED = (2, "Uploaded")
//         PROCESSING = (3, "Processing")
//         PROCESSED = (4, "Processed")
//         ERROR = (5, "Error")

export const VIDEO_STAGES=["Normal","Below Average","Average","Best","Viral","Trending","default"]
export const TEXT_CONTENT_TYPES = [CONTENT_ARTICLES,CONTENT_TOOLS]
export const VIDEO_CONTENT_TYPES = [CONTENT_VIDEOS]
export const CATEGORY_INDEX_UTIL = ["", CONTENT_VIDEOS, CONTENT_ARTICLES]

// page beadcrumbs
export const SETTINGS_CRUMBS=["","Profile","ChangePassword","Contactus"]


// url endpoints
export const URL_END_POINTS = {
    report:'support/report/'
}