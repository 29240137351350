export const ImageFileReader = (file) => {
  const Reader = new FileReader()
  return new Promise((resolve, reject) => {
    Reader.onerror = () => {
      Reader.abort()
      reject(new DOMException('Problem with image file.'))
    }

    Reader.onload = () => {
      resolve(Reader.result)
    }

    Reader.readAsDataURL(file)
  })
}
