/* eslint-disable */
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getUnixTime } from "date-fns";

function hyperCallbackHandler(eventData){
   try {
       if (eventData){
           const eventJSON = typeof eventData === 'string' ? JSON.parse(eventData) : eventData;
           const event = eventJSON.event
           // Check for event key
           if (event == "initiate_result") {
               //Handle initiate result here
               console.log("code initiate", eventData);
           } else if (event == "process_result") {
               //Handle process result here
               console.log("code processing", eventData);
           } else if (event == "user_event") {
               //Handle Payment Page events
           } else {
               console.log("Unhandled event",event, " Event data", eventData);
           }
       } else {
           console.log("No data received in event",eventData);
       }
   } catch (error) {
       console.log("Error in hyperSDK response",error);
   }
}
export default function useCheckout(){
    const [uid, setUid] = useState(uuidv4())
    const [juspayTarget, setJuspayTarget] = useState("") as any
    
    
    const initializeJuspaySdkPayload = {
        "action":'initiate',
        "clientId":'durvani',
        "merchantId":'durvani',
        "environment": 'sandbox',
        "integrationType":'iframe',
        "hyperSDKDiv":'durvanidiv',
    }
    const sdkPayload = {
        "service" : "in.juspay.hyperpay",
        "requestId": "0aeb46eb-a35c-4b93-bac4-0c985f40385c",
        "payload":initializeJuspaySdkPayload
    }
    const hyperServiceObject = new window.HyperServices();
        
    const initializeJuspaySdk =()=>{
        hyperServiceObject.initiate(sdkPayload, hyperCallbackHandler)
        // if(typeof window != "undefined"){
        
    // }
        
    }


    const processPayload = {
  "requestId": "8cbc3fad-8b3f-40c0-ae93-2d7e75a8624a",
  "service": "in.juspay.hyperpay",
  "payload": {
    "action": "paymentPage",
    "merchantId": "durvani",
    "clientId": "durvani",
    "orderId": "2003478291",
    "amount": "1.00",
    "customerId": "nagendrakumar422@gmail.com",
    "customerEmail": "nagendrakumar422@gmail.com",
    "customerMobile": "8099616022",
    "orderDetails": "{\"order_id\":\"2003478291\",\"amount\":\"1.00\",\"customer_id\":\"nagendrakumar422@gmail.com\",\"merchant_id\":\"durvani\",\"customer_email\":\"nagendrakumar422@gmail.com\",\"customer_phone\":\"8099616022\",\"return_url\":\"https:\\/\\/sandbox.juspay.in\\/end\",\"timestamp\":\"1711270905\"}",
    "signature": "eTiUvHLzWrLlR0uV3/0FD9cwNGbLBi12peeshVnCkeAci7PXNoQC0+pxg0Pz0fEfRX6t4dsGNtcyapDIXdUx9LTAexZz4nOt4MkLKBsQUal4gUmqQPT6TJECmu9l6//CZKlGFtcUd3IqzqYYk/cmS6HgOxyobFXBDtd5NLv8Qz4NkEZ869EtFxgI6cFbCB9PpJbn+6Wmr5vePBrmhrI2om9+u0Axgk6JA3T9MGkbVVxjXIdrAAAS68XjwyVlXe9X/T8JgvWmbfNOcyiMijVFadUoDbtn43sqWA8RWfkFEwwTuAklAqMsOTehnGVFdxBWrDScW+qEZTnRkR9XkOWCCw==",
    "merchantKeyId": "19381",
    "language": "english"
  }
}

if (hyperServiceObject.isInitialised()) {
	hyperServiceObject.process(processPayload);
    console.log("Processing")
} else {
  //Intialise hyperInstance
  initializeJuspaySdk()
}
const result = getUnixTime(Date.now())
console.log("unix date time", result)

const processSdk = () => {
 hyperServiceObject.process(processPayload);   
}

    
    return {setJuspayTarget,juspayTarget,hyperServiceObject,initializeJuspaySdk,processSdk}
}