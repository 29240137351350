import React, { useEffect, useState, useRef } from 'react'
import { CardContent, Card, Box, Slide } from '@mui/material'
import { faker } from '@faker-js/faker'
import Media from './ImageCard/Media'
import MediaPsedocontent from './ImageCard/MediaPsedocontent'
import WebsiteUrl from './ImageCard/WebsiteUrl'
import WebsiteTitle from './ImageCard/WebsiteTitle'
import WebsiteDescription from './ImageCard/WebsiteDescription'
import TagChips from './ImageCard/TagChips'
import DatenViews from './ImageCard/DatenViews'
import { CATEGORY_INDEX_UTIL, CONTENT_VIDEOS, LIKEORDISLIKETYPE } from '../../constants/constants'
import { green, red } from '@mui/material/colors'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { sleep } from '../../utils/util'
type Props = { category: string, id?: string, title?: string, description?: string, url?: string, publishedDate?: any, images?: string, customImage?: string, likeactions?: any,
tags:any[], isliked: boolean, islikedCount: number, item:any }

const ImageCard = (props: Props) => {
  const { category, id, title, description, url, publishedDate, images, customImage, isliked, tags, islikedCount, likeactions, item } = props
  const {
    handleGlobalState,
    globalState: { isModalOpen },
  }: any = useGlobalContext()
  const [isLiked, setIsLiked] = useState(isliked || false)
  const snakRef = useRef<any>()
  useEffect(() => {
    const scrollContainers: any = document.querySelectorAll('.chipscrollbar')
    for (let scrollContainer of scrollContainers) {
      if (!scrollContainer) return
      const scrollFun = (evt: any) => {
        evt.preventDefault()
        scrollContainer.scrollLeft += evt.deltaY / 12
      }
      scrollContainer.addEventListener('wheel', scrollFun)
    }

    return () => {
      for (let scrollContainer of scrollContainers) {
        if (!scrollContainer) return
        const scrollFun = (evt: any) => {
          evt.preventDefault()
          scrollContainer.scrollLeft += evt.deltaY
        }
        scrollContainer.removeEventListener('wheel', scrollFun)
      }
    }
  }, [])

  const LikeClickHandler = () => {
    setIsLiked(!isLiked)
    likeactions({ 
      contentcategory: LIKEORDISLIKETYPE.LIKESTOGGLE, 
      kind: CATEGORY_INDEX_UTIL.findIndex((elm)=> elm === category), 
      contentid: id, action: isLiked ? 2 : 1 })
    if (snakRef.current) {
      snakRef.current.click
    }
  }

  const handleModal = () => {    
    sleep(100).then(()=>{
      handleGlobalState('contentType_index', {category,item})
    })
  }

  return (
    <Slide
      direction="up"
      in={true}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: '2ms', transitionDuration: '1000ms' }}
    >
      <Card
        sx={{
          
          position: 'relative',
          borderRadius: 2,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          '&:hover': {
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            '.growwidth': { display: 'flex' },
          },
        }}
        className='responsive-img-card'
      >
        {/* Media Image */}
        <Media image={images || customImage} fallbackimage={customImage} />
        {/* Media Image Psedo content likes and count */}
        <MediaPsedocontent
          LikeClickHandler={LikeClickHandler}
          isLiked={isLiked}
          totallikes={islikedCount}
        />
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            onClick={handleModal}
          >
            <WebsiteUrl url={url || ""} />
            {/* Title */}
            <WebsiteTitle
              title={title || ""}
            />
            {/* Description */}
            <WebsiteDescription
              description={description || ""}
            />
            {/* Chips tags */}
            <TagChips />
            {/* bottom views and time */}
            {category !== CONTENT_VIDEOS && <DatenViews date={publishedDate || new Date()} kind={category} />}
          </Box>
        </CardContent>
      </Card>
    </Slide>
  )
}

export default ImageCard
