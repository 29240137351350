import axiosInstance from "../utils/axiosInstance";
import { 
    LoginMutationSubmitMutation, 
    RegisterMutationSubmitMutation, 
    ForgotMutationSubmitMutation, 
    ResetMutationSubmitMutation } from "./graphql/Mutations";

export const authLogin = (body: any) => {
    return axiosInstance.post(`graphql/`,
        {
            query: LoginMutationSubmitMutation,
            variables: { input: body }
        })
}

export const authRegister = (body: any) => {
    return axiosInstance.post(`graphql/`,
        {
            query: RegisterMutationSubmitMutation,
            variables: { input: body }
        })
}

export const authForgot = (body: any) => {
    return axiosInstance.post(`graphql/`,
        {
            query: ForgotMutationSubmitMutation,
            variables: { input: body }
        })
}

export const authReset = (body: any) => {
    return axiosInstance.post(`graphql/`,
        {
            query: ResetMutationSubmitMutation,
            variables: { input: body }
        })
}

export const logOut = () => {
    localStorage.clear();
    return window.location.assign("/")
}
