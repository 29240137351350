import React from 'react'
import {Toolbar, IconButton, Typography, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type Props = {handleClose: () => void, title?:any}

const ModalToolbar = (props: Props) => {
  const {handleClose, title} = props
  return (
    <Toolbar>
      <IconButton
        edge="start"
        color="info"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon color="success" />
      </IconButton>
      <Typography
        sx={{ml: 2, flex: 1, color: 'success'}}
        variant="h6"
        component="div"
        noWrap
      >
        {title}
      </Typography>
      <Button
        autoFocus
        color="success"
        variant="contained"
        onClick={handleClose}
      >
        Close
      </Button>
    </Toolbar>
  )
}

export default ModalToolbar
