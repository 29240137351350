import React from 'react';
import {
    Box,
    IconButton,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: grey[50],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: grey[200],
    },
}))
type Props = { fullcontent: string, children:any, position:any }

const ContentTooltip = (props: Props) => {
    const { fullcontent, position, children } = props;
  return (
        <Box
        >
            <LightTooltip
                placement={position}
                title={fullcontent}
                arrow={true}
                sx={{
                    'tooltipClasses.tooltip': {
                        backgroundColor: 'red',
                        color: 'rgba(0, 0, 0, 0.87)',
                    },
                }}
            >
                {children || ""}
            </LightTooltip>
        </Box>
    )
}

export default ContentTooltip