import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {purple, teal, red} from '@mui/material/colors'
import {GlobalContextProvider} from './contexts/GlobalContext'

const outerTheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      // main: '#7551e9',
      main: '#9747ff',
      dark: '#5138a3',
      light: '#9073ed',
      darkxl: purple[900],
      contrastText: '#fff',
    },
    secondary: {
      main: teal['A200'],
      dark: teal['A400'],
      light: teal['A100'],
      darkxl: teal[900],
      contrastText: '#000',
    },
    success: {
      main: '#ffd600',
      dark: '#f9a825',
      light: '#fff59d',
    },
    error: {
      main: red['A400'],
    },
    defaultlight: {
      main: '#f5f5f5',
      light: '#eeeeee',
      dark: '#bdbdbd',
      darker: '#e0e0e0',
      darkersm: '#bdbdbd',
      darkermd: '#9e9e9e',
      darkxl: '#424242',
      contrastText: '#000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          minWidth: '7rem',
          minHeight: '2.6rem',
        },
      },
    },
  },
})
// comment is added
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <GlobalContextProvider>
    <ThemeProvider theme={outerTheme}>
      <App />
    </ThemeProvider>
  </GlobalContextProvider>
)
