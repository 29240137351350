import {Typography} from '@mui/material'
import React from 'react'

type Props = {description: string}

const ModalDescription = (props: Props) => {
  const {description} = props
  return (
    <Typography component="h3" variant="body2" color="defaultlight.darkermd">
      {description}
    </Typography>
  )
}

export default ModalDescription
