import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal(props) {
  const { modalHandle, isopen, filterset } = props

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      // count: Yup.number().integer()
      //   .max(200, 'Must be 200 or less')
      //   // .matches(
      //   //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      //   // )
      //   .required('Required')
    }),

    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      await filterset({title:values.title})
      // await fetchTrendsMutation({keyword:values.keyword,count:values.count})
      // console.log(searchid)
      // sleep(3000).then(() => formik.setSubmitting(false));     

    },
  });

  return (
    <div>

      <Dialog
        open={isopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={modalHandle}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle>{"Search"}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Box display="flex" justifyContent="start" alignItems="center">
                <TextField
                  helperText={formik.errors?.title || ''}
                  id="title-field"
                  label="Search"
                  name="title"
                  fullWidth
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={Boolean(formik.touched.title && formik.errors.title) || false}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2 }}>
            <Button type="submit" variant="contained">Apply</Button>
            <Button onClick={modalHandle} type="button" variant="outlined">Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>

    </div>
  )
}
