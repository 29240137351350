import React, {useState} from 'react'
import {Box, Typography, TextField, Stack, useMediaQuery} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import Icons from './shared/Icons/Icons'

type Props = {}

const ChangePassword = (props: Props) => {
  const matches = useMediaQuery('(min-width:600px)')
  const [loading, setLoading] = useState(false)
  return (
    <>
      <Box
        sx={{borderBottom: 0.3, borderColor: 'grey.300'}}
        py={3}
        px={3}
        className="relative fadein"
      >
        <Typography variant="h5" component="h3" fontWeight="bold">
          Change Password
        </Typography>
        {/* <div style={{}}> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{position: 'absolute', top: -110, right: 0}}
          className="settingstopwave"
        >
          <path
            fill="#4db6ac"
            fillOpacity="1"
            d="M0,96L80,128C160,160,320,224,480,240C640,256,800,224,960,176C1120,128,1280,64,1360,32L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
        {/* </div> */}
      </Box>
      <Box p={3} className="fadein">
        <Box className="relative">
          <form>
            <Stack direction="column" gap={4}>
              <TextField
                helperText=""
                id="demo-helper-text-aligned"
                label="Password"
                autoFocus
                sx={{mt: 1, width: '50%'}}
              />
              <TextField
                helperText=""
                id="demo-helper-text-aligned"
                label="Confirm Password"
                autoFocus
                sx={{mt: 1, width: '50%'}}
              />
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="large"
                sx={{textTransform: 'inherit', px: 8, width: '50%'}}
              >
                {loading ? 'Please wait...' : 'Update'}
              </LoadingButton>
            </Stack>
          </form>
          {matches && (
            <>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '15%',
                  right: '9rem',
                  zIndex: 2,
                }}
              >
                <Icons
                  color="secondary"
                  animprop="default"
                  icontype="Usertyping"
                  animtype="noanim"
                />
              </div>
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '3rem',
                  width: 300,
                  zIndex: 1,
                }}
              >
                <path
                  fill="#fff3e0"
                  d="M63.1,-47.3C79.2,-30.2,87.9,-4.1,82.3,18.2C76.8,40.5,57,58.9,35.1,67.2C13.3,75.5,-10.7,73.7,-27.2,63.2C-43.7,52.6,-52.8,33.4,-55.9,14.3C-59,-4.7,-56.1,-23.6,-45.8,-39.4C-35.5,-55.3,-17.7,-68.2,2.9,-70.5C23.5,-72.8,47,-64.5,63.1,-47.3Z"
                  transform="translate(100 100)"
                />
              </svg>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ChangePassword
