/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Button, Box, Container, Typography} from '@mui/material'
import {
  amber,
  deepPurple,
  green,
  purple,
  teal,
  blue,
} from '@mui/material/colors'
import React, {useState} from 'react'
import {AlertToast, CardsContainer, Modal, NavBar, PageBreadcrumbs} from '../components'
import {content} from '../utils/images'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'
import useContentstore from '../hooks/useContentstore'
import { ALERT_ERROR, ALERT_SUCCESS, CONTENT_ARTICLES } from '../constants/constants'

const Articles = () => {
  const [relaycursor, setRelaycursor] = useState("");
  const [filterinput, setFilterinput] = useState<any>({kind:1})
  const { loading, contentdata, likeordislike, error, hasMore, statusmsg, isopen, setIsopen } = useContentstore(relaycursor,filterinput)
  const matches = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = useState(false)
  
  return (
    <div className="relative">
      <Box sx={{my: 4, mx: {sx: 3, sm: 4}}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body1" component="h3" color="grey.600">
              Articles
            </Typography>
          </Box>
          <Box>
            <PageBreadcrumbs />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
        >
          <Box>
            <Modal isopen={open} modalHandle={() => setOpen(!open)} filterset={(data) => setFilterinput(prevdata=>[...prevdata, data])} />
          </Box>
          <Box
            onClick={() => setOpen(!open)}
            boxShadow={3}
            sx={{
              cursor: 'pointer',
              position: 'fixed',
              right: 10,
              bottom: 10,
              backgroundColor: teal['A400'],
              width: 50,
              height: 50,
              borderRadius: '100%',
              '&:hover': {backgroundColor: teal[400]},
              zIndex: 10,
            }}
            display="flex"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <FilterAltOutlinedIcon sx={{color: 'white'}} />
          </Box>
        </Box>
        <CardsContainer data={contentdata} category={CONTENT_ARTICLES} likeactions={likeordislike} hasMore={hasMore} loadactions={() => {
          window.scrollBy(0, 10);
          setRelaycursor(contentdata.at(-1).cursor)
        }} isloading={loading} />
      </Box>
      <AlertToast title={statusmsg} level={error?ALERT_ERROR:ALERT_SUCCESS} isOpen={isopen} onClose={() => setIsopen(false)} />
    </div>
  )
}

export default Articles
