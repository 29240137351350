export interface ProxyObjectType { }

const emptyProxyObject: ProxyObjectType = new Proxy(
  {},
  {
    get: () => {
      return emptyProxyObject;
    },
  }
);
export const isEmpty = (val: any) => {
  // Reference: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

export const navigationroute = (routepath: string) => {
  return routepath
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(() => {
    resolve(ms || 1000)
  }, ms || 1000))
};

export const parseToken = (token: any) => {
  const tokenParts = JSON.parse(atob(token.split('.')[1]));
  // exp date in token is expressed in seconds, while now() returns milliseconds:
  const now = Math.ceil(Date.now() / 1000);
  if (now > tokenParts.exp) {
    return false
  } else {
    return true
  }
};

export const decodeUrl = (encodedVal: string | undefined) => {
  if (encodedVal !== undefined) {
    if (encodedVal.length % 4) {
      encodedVal = encodedVal + "=".repeat(4 - (encodedVal.length % 4));
    }
    try {
      return JSON.parse(window.atob(encodedVal));
    } catch (err) {
      return { error: true };
    }
  }

};

export const statusDeterminer = (errortext: string) => {
  switch (errortext) {
    case 'Email is invalid!':
      return 'Please provide a valid email!';
    case 'The email does not match any user!':
      return 'User doesn"t exists!';
    default:
      return 'Something went wrong!';
  }
};

