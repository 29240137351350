import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Link,
  Grow,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, decodeUrl, sleep } from '../utils/util';
import { ALERT_ERROR } from '../constants/constants';
import { AlertToast } from '../components';
import { authReset } from '../api/AuthMutations';
import { useMutation } from '@tanstack/react-query';

const Reset = () => {
  const navigate = useNavigate()
  let { token } = useParams<any>();
  const [resetError, setResetError] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState<string>("")
  const { mutate: ResetMutation, isPending:isLoading, isSuccess } = useMutation({mutationKey:["reset"], mutationFn:authReset})

  if(isEmpty(token)){
    return <div className="text-center p-5 m-5 text-warningerror bg-error">Token is not found.</div>
  }

  let params = decodeUrl(token);

  if (params && params.error) {
    return <div className="text-center p-5 m-5 text-warningerror bg-error">Invalid token or link expired.</div>
  }

  const formik = useFormik({
    initialValues: {
      passwordOne: '',
      passwordTwo: '',
    },
    validationSchema: Yup.object({
      passwordOne: Yup.string().min(8, 'Password is too short!').required('Required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      passwordTwo: Yup.string().required('Required')
        // .test('passwords-match', 'Passwords must be matched!', (value: any) => (this.parent.passwordTwo === value))
        .oneOf([Yup.ref('passwordOne'), null], 'Passwords must match')

    }),
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      const formdata = {
        password:values.passwordOne,
        confirmPassword:values.passwordTwo,
        userId:params.id,
        token:params.token,
      }
      ResetMutation(formdata, {
        onSuccess: ({ data }) => {
          if ("errors" in data) {
            setResetError(true);
            // const msg = statusDeterminer(data.errors[0].message)
            setErrormsg(data.errors[0].message);
          } else {
            const { data: { resetPassword: { user } } } = data
            if (!user.isActive) {
              setErrormsg("User account is disabled or not activated!");
              setResetError(true);
            } else {
              sleep(1000).then(() => {
                window.localStorage.clear();
              }).then(() => {
                 sleep(1000).then(() => {
                window.location.assign("/")
              })
              })
             
              
              
            }
          }
        },
        onError: (data:any) => {
          if (!isEmpty(data?.response)) {
            setErrormsg("Bad request! Please try again!");
          }else{
            setErrormsg("NetWork error! Please try again!");    
          }          
          setResetError(true);
        }
      })
      // sleep(3000).then(() => formik.setSubmitting(false));     

    },
  });

  return (
    <div className="relative" style={{ width: '100%', overflow: 'hidden' }}>
      <Grow in={true}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: '100vh' }}
          spacing={2}
        >
          <Grid item md={4} lg={4} className="relative logindotanimtrigger">
            <Box
              boxShadow={5}
              borderRadius={4}
              sx={{ overflow: 'hidden', backgroundColor: 'white' }}
            >
              <Stack
                spacing={6}
                p={6}
                className="loginbox"
                sx={{ overflow: 'hidden' }}
              >
                <Box
                  sx={{ position: 'relative', zIndex: 3, margin: 'auto' }}
                  alignContent="center"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'white',
                      fontStyle: 'italic',
                      '&:after': {
                        content: '""',
                        width: 250,
                        height: 50,
                        position: 'absolute',
                        display: 'inline-block',
                        backgroundColor: '#aa00ff',
                        zIndex: -10,
                        transform: 'skew(-3deg)',
                        top: '-0.5rem',
                        bottom: '-0.25rem',
                        left: '-1.8rem',
                        right: '-0.25rem',
                        borderRadius: '0.3rem',
                      },
                    }}
                  >
                    Reset Password
                  </Typography>
                </Box>
                <Box
                  component="div"
                  style={{
                    width: 20,
                    height: 20,
                    background: '#ffa726',
                    position: 'absolute',
                    right: -10,
                    top: -40,
                    borderRadius: '100%',
                    opacity: 0.8,
                    transition: 'all 2s ease-in-out',
                  }}
                  className="loginanimdot"
                />
                <Box sx={{ width: '100%' }}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      helperText={formik.errors?.passwordOne||''}
                      type="password"
                      id="password-one"
                      name="passwordOne"
                      label="New Password"
                      fullWidth
                      autoFocus
                      sx={{ mt: 1 }}
                      onChange={formik.handleChange}
                      value={formik.values.passwordOne}
                      error={Boolean(formik.touched.passwordOne && formik.errors.passwordOne) || false}
                    />
                    <TextField
                      helperText={formik.errors?.passwordTwo||''}
                      type="password"
                      id="password-two"
                      name="passwordTwo"
                      label="Confirm Password"
                      fullWidth
                      sx={{
                        mt: 4,
                        // '&.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root': {
                        // fontSize:'0.5rem',}
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.passwordTwo}
                      error={Boolean(formik.touched.passwordTwo && formik.errors.passwordTwo) || false}
                    />
                    <LoadingButton
                      fullWidth
                      loading={isLoading}
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      sx={{
                        mt: 4,
                        borderRadius: 1.5,
                        px: 5,
                        textTransform: 'inherit',
                      }}
                    >
                      Reset Password
                    </LoadingButton>
                  </form>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Link
                      component="p"
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="primary.main"
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                    >
                      Login to account?
                    </Link>
                    <Link
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="secondary.darkxl"
                      onClick={() => {
                        navigate('/auth/register')
                      }}
                    >
                      Register account?
                    </Link>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grow>
      <AlertToast title={errormsg} level={ALERT_ERROR} isOpen={resetError} onClose={() => setResetError(false)} />
    </div>
  )
}

export default Reset;
