import React from 'react'
import {Grid, Stack, useTheme, Paper} from '@mui/material'
import {Link} from 'react-router-dom'
import {logo} from '../utils/images'
import {amber} from '@mui/material/colors'
import NavbarMenu from './componentsparts/NavbarMenu'
import { useLocation } from 'react-router-dom';
import clsx from "clsx";
import MainNavbarManu from './componentsparts/MainNavbarManu'

const NavBar = (props) => {
  let location = useLocation();
  const theme = useTheme()
  const variableLogo = logo as any
  let pathname = location.pathname?.split("/").pop()
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}
    >
      <Paper elevation={2} sx={{py: 3}} square>
        <Grid container spacing={3}>
        {props?.matches && <Grid item md={8} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                background: 'transparent',
                display: 'flex',
                justifyContent: 'between',
                flexWrap: 'wrap',
                gap: 3,
                px: 3,
              }}
            >
              <Link to="/" style={{display: 'flex', alignItems: 'center'}}>
                <img src={variableLogo} alt="logo" height={!props?.matches?30:45} />
              </Link>
              <Link to="/" className="dnd__navlink">
                Home
              </Link>
              <Link to="/content/articles" className={clsx("dnd__navlink", pathname==="articles" && "active")}>
                Articles
              </Link>
              <Link to="/content/videos" className={clsx("dnd__navlink", pathname==="videos" && "active")}>
                Videos
              </Link>
              <Link to="/content/tools" className={clsx("dnd__navlink", pathname==="tools" && "active")}>
                Tools
              </Link>
              <Link to="/content/trends" className={clsx("dnd__navlink", pathname==="trends" && "active")}>
                Trends
              </Link>
            </Stack>
          </Grid>}
          <Grid item md={4} xs={12}>
            <Stack  direction={{ xs: 'row', sm: 'column', md:'column', lg:'column' }}  sx={{width:"100%"}}>
              {!props.matches && <Link to="/" style={{display: 'flex', alignItems: 'center'}}>
                <img src={variableLogo} alt="logo" height={!props?.matches?30:45} />
              </Link>}
              {!props.matches && <MainNavbarManu />}
              <NavbarMenu />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default NavBar
