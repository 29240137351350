import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  Grid,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {amber, green, grey, pink, purple, teal} from '@mui/material/colors'
import {linestroke, Checkmark} from '../utils/images'
import useCheckout from '../hooks/useCheckout'

const label = {inputProps: {'aria-label': 'Color switch demo'}}
const Pricing = () => {
  const matches = useMediaQuery('(min-width:600px)')
  const [isSwitched, setIsSwitched] = useState(false)
  const {setJuspayTarget,juspayTarget,initializeJuspaySdk,hyperServiceObject,processSdk} = useCheckout();
  
  useEffect(() => {
    if(juspayTarget){
    setJuspayTarget(initializeJuspaySdk())}
    console.log(juspayTarget)
  },[juspayTarget])
  
  const svgcheckmark = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check_ring_round">
        <path
          id="checktickmarkcircle"
          d="M13.5 23.625C15.8425 23.625 18.1125 22.8128 19.9232 21.3267C21.734 19.8407 22.9735 17.7728 23.4305 15.4753C23.8874 13.1778 23.5337 10.793 22.4295 8.72711C21.3252 6.66123 19.5388 5.04215 17.3747 4.14572C15.2105 3.24929 12.8025 3.131 10.5609 3.81098C8.31926 4.49096 6.38278 5.92716 5.08137 7.87485C3.77996 9.82254 3.19415 12.1612 3.42375 14.4924C3.65336 16.8236 4.68416 19.0031 6.34054 20.6595"
          stroke="#33363F"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="checktickmark"
          d="M18 11.25L13.777 16.3175C13.1215 17.1042 12.7937 17.4976 12.3531 17.5176C11.9125 17.5375 11.5505 17.1755 10.8264 16.4514L9 14.625"
          stroke="#33363F"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
  const svgclosemark = (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="close_square_duotone_line">
        <circle
          id="circle"
          cx="12"
          cy="12"
          r="8.4"
          stroke="#2A4157"
          strokeOpacity="1"
        />
        <path
          id="rightbottomline"
          d="M16 8L8 16"
          stroke="#222222"
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          id="leftbottomline"
          d="M8 8L16 16"
          stroke="#222222"
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )

  return (
    <div className="relative">
      <Box display="flex" justifyContent="center">
        <Stack gap={2} alignItems="center">
          <Box sx={{position: 'relative'}}>
            <Typography component="h1" variant="h4" color="default">
              Pricing plans
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                top: 35,
                left: 40,
                width: 100,
                height: 600,
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${linestroke})`,
              }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: amber[400],
              borderRadius: 8,
              px: 4,
              fontWeight: 'bold',
              my: 1,
            }}
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Typography
              component="h6"
              variant="body2"
              sx={{dispay: 'inline', fontWeight: 'bold'}}
              color={!isSwitched ? teal[700] : grey[900]}
            >
              Monthly
            </Typography>
            <Switch
              size="medium"
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: isSwitched ? 'primary.main' : teal[400],
                },
                '& .MuiSwitch-track': {
                  backgroundColor: isSwitched ? 'primary.main' : teal[400],
                },
              }}
              onChange={() => setIsSwitched(!isSwitched)}
              checked={isSwitched}
              {...label}
            />
            <Typography
              component="h6"
              variant="body2"
              sx={{dispay: 'inline', fontWeight: 'bold', borderRadius: 8}}
              color={isSwitched ? purple[700] : grey[900]}
            >
              Yearly
            </Typography>
          </Box>
        </Stack>
      </Box>
      {/* MuiSwitch-root MuiButtonBase-root MuiSwitch-thumb             */}
      <Grid container spacing={3} maxWidth="md" sx={{mx: 'auto', mt: 1}}>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{position: 'relative', overflow: 'hidden', borderRadius: 3}}
            className="svganimationicon"
          >
            <Stack
              sx={{
                minHeight: '50vh',
                border: 1,
                borderColor: '#dce775',
                borderRadius: 3,
                borderTop: 8,
                borderTopColor: 'success.main',
                background: 'white',
              }}
              boxShadow={2}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.100',
                  width: '100%',
                  px: 5,
                  py: 2,
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Typography component="h3" variant="h6">
                  Basic
                </Typography>
                <Box className="titlecircle" />
              </Box>
              <Box
                sx={{mx: 'auto'}}
                display="flex"
                p={3}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgcheckmark}
                  <Typography component="h3" variant="subtitle2">
                    Articles
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgcheckmark}
                  <Typography component="h3" variant="subtitle2">
                    Videos
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgclosemark}
                  <Typography component="h3" variant="subtitle2">
                    Tools
                  </Typography>
                </Box>

                <span>19$/mo</span>
                <Box mt={8}>
                  <Button
                    color="success"
                    variant="contained"
                    sx={{textTransform: 'none'}}
                  >
                    Start Now
                  </Button>
                </Box>
              </Box>
            </Stack>
            <Box
              sx={{
                position: 'absolute',
                width: 60,
                height: 60,
                bottom: -10,
                right: -10,
                backgroundColor: teal['A100'],
                borderRadius: 50,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: 20,
                height: 20,
                bottom: 10,
                right: 10,
                backgroundColor: teal['A200'],
                borderRadius: 50,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{position: 'relative', overflow: 'hidden', borderRadius: 3}}
            className="svganimationicon"
          >
            <Stack
              sx={{
                minHeight: '50vh',
                border: 1,
                borderColor: '#dce775',
                borderRadius: 3,
                borderTop: 8,
                borderTopColor: 'primary.main',
                background: 'white',
              }}
              boxShadow={2}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.100',
                  width: '100%',
                  px: 5,
                  py: 2,
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Typography component="h3" variant="h6">
                  Pro
                </Typography>
                <Box className="titlecircle delay5" />
              </Box>
              <Box
                sx={{mx: 'auto'}}
                display="flex"
                p={3}
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgcheckmark}
                  <Typography component="h3" variant="subtitle2">
                    Articles
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgcheckmark}
                  <Typography component="h3" variant="subtitle2">
                    Videos
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{gap: 1}}
                >
                  {svgcheckmark}
                  <Typography component="h3" variant="subtitle2">
                    Tools
                  </Typography>
                </Box>
                <span>19$/mo</span>
                <Box mt={8}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{textTransform: 'none'}}
                    onClick={processSdk}
                  >
                    Start Now sdk
                  </Button>
                </Box>
              </Box>
            </Stack>
            <Box
              sx={{
                position: 'absolute',
                width: 60,
                height: 60,
                bottom: -10,
                right: -10,
                backgroundColor: pink['A100'],
                borderRadius: 50,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: 20,
                height: 20,
                bottom: 10,
                right: 10,
                backgroundColor: pink['A200'],
                borderRadius: 50,
              }}
            />
          </Box>
          <div id="durvanidiv" style={{width:"600px", height:"800px"}}></div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Pricing
