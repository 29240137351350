import React from 'react'
import {
  Typography,
  CardMedia,
  CardContent,
  Card,
  Chip,
  Box,
  Slide,
  Zoom,
  IconButton,
  styled,
} from '@mui/material'
type Props = {
  image: any,
  fallbackimage?:string,
  iswidthed?:boolean
}

const Media = (props: Props) => {
  const { image, fallbackimage, iswidthed } = props
  const onMediaFallback = event => event.target.src = fallbackimage;
  return (
    <CardMedia
      component="img"
      height={iswidthed?"auto":"240"}
      image={image}
      alt="green iguana"
      sx={{
        position: 'relative',
        maxHeight: iswidthed? "fit-content":240,
        borderRadius: 2,
        transition: 'all 1s ease-in-out',
        overflow: 'hidden',
        '&:hover': {transform: 'scale(1.03)', opacity: 0.8},
      }}
      onError={onMediaFallback}
      
    />
  )
}

export default Media
