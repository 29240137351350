import {Box, Typography} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import React from 'react'
import ContentTooltip from '../../../components/shared/Tooltips/ContentTooltip'

type Props = {
  title: string
}

const WebsiteTitle = (props: Props) => {
  const {title} = props
  return (
    <Box mt={1} className="cursor-pointer">
      <ContentTooltip fullcontent={title} position="right">
        <Typography gutterBottom variant="h5" component="div" noWrap>
        {title}
      </Typography>
      </ContentTooltip>
      
    </Box>
  )
}

export default WebsiteTitle
