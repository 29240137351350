import {CONTENT_ARTICLES} from '../constants/constants'
import React, {createContext, useState, useContext, useCallback} from 'react'

const initialState = {
  contentType: CONTENT_ARTICLES,
  isModalOpen: false,
  currentIndex: null
}
const GlobalContext = createContext({})

export const GlobalContextProvider = ({children}) => {
  const [globalState, setGlobalState] = useState(initialState)

  const handleState = useCallback((payload, value) => {
    if (payload === "currentIndex") {
      setGlobalState({...globalState, isModalOpen: true, currentIndex:value})
    } 
    else if(payload === "contentType_index"){
      setGlobalState({...globalState, isModalOpen: true, contentType:value.category,currentIndex:value.item})
    }
    else {
      setGlobalState({...globalState, [payload]: value})
    }
    
  }, [])
  return (
    <GlobalContext.Provider
      value={{globalState: globalState, handleGlobalState: handleState}}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)
