export const LoginMutationSubmitMutation = `
    mutation loginmutation($input: LoginInput!){
        login(input:$input){    
            token
            refreshExpiresIn
            user{
                isActive
                id
            }
        }
    }
`

export const RegisterMutationSubmitMutation = `
    mutation registermutation($input: RegisterInput!){
        register(input:$input){    
            user{
                verificationToken      
              }
        }
    }
`

export const ForgotMutationSubmitMutation = `
    mutation forgotPasswordmutation($input:ForgotPasswordInput!) {
        forgotPassword(input:$input){
            user {
                isActive
            }
        }
    }  

`
export const ResetMutationSubmitMutation = `
    mutation resetPasswordmutation($input: ResetPasswordInput!) {
        resetPassword(input:$input){
        user{
            isActive
        }
        }
    }
`
// {contentcategory:1,kind:2,contentid:"ContentStoreType@5daf8c5f-e094-4cb4-b1f0-7ae864f7d537",action:1}
export const ContentAddorUpdateMutation = `
    mutation LibraryLikeorDislikeMutation($input: LibraryGroupMutationInput!) {
        libraryGroupAction(input:$input) {
            Status{
                message
            }
        }
    }
`
// {kind:"1", name:"sd", mimetype:"", profilepic:""}
export const FileUploadMutation = `
        mutation Upload($input: UploadFileInput!) {
            UploadFile(input: $input) {
                user {
                    message
                }
            }
        }
`

export const UpdateUserinfobyUserMutation = `
        mutation updateuserinfo($input: UpdateUserbyUserInput!) {
            updateUserByUser(input:$input){
            user{
                username
            }
            }
        }
`
// {keyword:"photoshop",count:50}
export const TrendDumpMutation = `
    mutation TrendsContentMutation($input:TrendsMutationInput!){
        dumpTrends(input:$input){
        Status{
            message
            searchtermid
        }
        }
    }

`