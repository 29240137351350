import axiosInstance from "../utils/axiosInstance"

export const requestPostApi = (endpoint:any, form_data:any): Promise<any> => {
    
    return new Promise((resolve, reject) => {
        axiosInstance
      .post(endpoint, form_data).then(({ data: { data } }) => {
        return resolve(data);       
      }).catch(() => {
        return resolve(400)
      });
    })
}