import axios from 'axios';

const baseURL = `${process.env.apiurl}`;
const axiosInstance = axios.create({
  baseURL,
  // timeout: 5000,
  headers: {
    Authorization: `JWT ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error),
);
export default axiosInstance;