import React, { useEffect } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { NavBar } from '../components'
import { Outlet } from 'react-router-dom'
import { teal } from '@mui/material/colors'
import { fetchQuery } from '../api/ContentQueries'
import { meQuery } from '../api/graphql/Queries'



const HomeLayout = (props) => {
  const matches = useMediaQuery('(min-width:600px)')
  useEffect(() => {

    try {
      fetchQuery(meQuery, {}).then((data) => {
        const { me } = data;
        localStorage.setItem("authdata", JSON.stringify(me || {}))
      })
    } catch {
      console.log("mequery is failed!");
    }


  }, [])
  
  return (
    <div className="relative">
      <Box sx={{ boxShadow: 'none' }}>
        <NavBar matches={matches} />
        <Box sx={{ my: 4, mx: { sx: 3, sm: 4 } }}>
          <div>
            <Outlet />
          </div>
        </Box>
      </Box>
      {matches && (
        <>
          <div
            className="bigbubble"
            style={{
              position: 'fixed',
              backgroundColor: teal[100],
              left: '60%',
              top: 0,
              width: 600,
              height: 600,
              zIndex: -1,
              borderRadius: '100%',
            }}
          />
          <div
            className="bigbubble"
            style={{
              position: 'fixed',
              left: 20,
              bottom: 38,
              width: 800,
              height: 800,
              zIndex: -1,
              borderRadius: '100%',
            }}
          />{' '}
        </>
      )}
      <div
        className="authbubble smallbubblebounce"
        style={{ position: 'fixed', width: 20, height: 20, left: 8 }}
      />
      <div
        className="authbubble bigbubblebounce"
        style={{ position: 'fixed', width: 30, height: 30, left: 12 }}
      />
    </div>
  )
}

export default HomeLayout
