import React, {useEffect, useRef} from 'react'
import heromain from '../assets/images/hero-main.png'
import herocardthumb from '../assets/images/hero-card-thumb.png'
import featureintro from '../assets/images/feature-intro.png'
import logo from '../assets/images/logo.png'
import {Box, Button, Grid, Link, Stack, Typography} from '@mui/material'
import {amber, green} from '@mui/material/colors'
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import {useNavigate} from 'react-router-dom'
import {navigationroute} from '../utils/util'

type Props = {}

function scrollFactory({
  heroContainer,
  centerHeroCard,
  heroCardOne,
  heroCardTwo,
  heroCardThree,
}) {
  let centerHeroTop
  let videoContainerTop
  let sliderContainerHeight
  let sliderContainerTop
  let featureContainerHeight
  let featureContainerTop

  function animatePosY() {
    const posY = Math.min(window.pageYOffset / centerHeroTop, 1)
    /// card-1
    heroCardOne.current.style.transform = `translate3d(${posY * 200}% , ${
      posY * 400
    }%, 0) rotate(${-20 + posY * 60}deg) scale(${1 - posY * 0.2})`
    heroCardOne.current.style.opacity = `${1 - posY * 0.8}`

    /// //card-2
    heroCardTwo.current.style.transform = `translate3d(${posY * 200}% , ${
      posY * 400
    }%, 0) rotate(${-20 + posY * 40}deg) scale(${1 - posY * 0.2})`
    heroCardTwo.current.style.opacity = `${1 - posY * 0.8}`

    /// ///card-3
    heroCardThree.current.style.transform = `translate3d(${posY * -200}% , ${
      posY * 400
    }%, 0) rotate(${15 - posY * 30}deg) scale(${1 - posY * 0.2})`
    heroCardThree.current.style.opacity = `${1 - posY}`

    /// //center-card
    centerHeroCard.current.classList.toggle('popup', posY > 0.9)
  }
  /// /////////
  return function () {
    centerHeroTop = centerHeroCard.current?.offsetTop || 0
    console.log('logging')
    if (window.pageYOffset < window.innerHeight) {
      if (window.innerWidth > 767) {
        console.log('request frame')
        requestAnimationFrame(animatePosY)
      }
    }
  }
}

const Landing = (props: Props) => {
  const navigate = useNavigate()
  const heroContainer = useRef<any>()
  const heroImage = useRef<any>()
  const heroCardOne = useRef<any>()
  const heroCardTwo = useRef<any>()
  const heroCardThree = useRef<any>()
  const centerHeroCard = useRef<any>()

  useEffect(() => {
    const scrollConsole = scrollFactory({
      heroContainer,
      centerHeroCard,
      heroCardOne,
      heroCardTwo,
      heroCardThree,
    }) as any
    window.addEventListener('scroll', scrollConsole, true)

    return () => {
      window.removeEventListener('scroll', scrollConsole, true)
    }
  }, [])

  const routetransfer = (pathname: string) => {
    navigate(navigationroute(pathname))
  }

  return (
    <div>
      <section
        className="main-navbar"
        style={{position: 'relative', zIndex: 3}}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            width: '100%',
            right: 0,
            left: 0,
            background: 'rgba(255,255,255, 0.7)',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            alignContent="center"
            boxShadow="none"
            justifyContent="space-between"
            sx={{height: '5em'}}
          >
            <Box ml={2}>
              <img src={logo} alt="logo" />
            </Box>
            <Box sx={{ml: 'auto'}} p={3}>
              <Stack direction="row" gap={2} sx={{height: '100%'}}>
                <Box>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => routetransfer('/pricing')}
                  >
                    Pricing
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => routetransfer('/auth/login')}
                  >
                    Login
                  </Button>
                </Box>
                <Box>
                  <Button color="success" variant="contained" disableElevation>
                    Contactus
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </section>
      <section className="main-hero" ref={heroContainer}>
        <div className="semitransparentbg">
          <div
            style={{
              width: '400px',
              height: '400px',
              borderRadius: '100%',
              opacity: 1,
            }}
            className="big-gradient-circle gradient-anim red-gradient"
          />
          <div
            style={{
              width: '450px',
              height: '450px',
              borderRadius: '100%',
              opacity: 1,
            }}
            className="big-gradient-circle gradient-anim delay-1 green-gradient"
          />
          <div
            style={{
              width: '450px',
              height: '450px',
              borderRadius: '100%',
              opacity: 0.2,
            }}
            className="big-gradient-circle gradient-anim delay-2 violet-gradient"
          />
        </div>
        <div className="container">
          <Box sx={{position: 'relative'}}>
            <Typography component="h1" variant="h2">
              See your next hire through a{' '}
              <div className="stroke" style={{display: 'inline-block'}}>
                new lens.
              </div>
            </Typography>
            <p>
              Quickly create job listings using video to help attract quality
              candidates using DND Recruit.
            </p>
            <Link
              href="/pricing"
              sx={{
                px: 5,
                py: 2,
                backgroundColor: 'primary.main',
                color: 'white',
                borderRadius: 2,
              }}
            >
              Check Pricing
            </Link>
          </Box>
          <Box sx={{mt: '30vh'}}>
            <div className="hero-image" ref={heroImage}>
              <img placeholder="blurred" src={heromain} alt="" width={380} />

              <div className="hero-card card-1" ref={heroCardOne}>
                <svg
                  className="hero-card-inner"
                  width="0"
                  height="0"
                  viewBox="0 0 204 113"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x=".327"
                    y=".656"
                    width="203"
                    height="112"
                    rx="8"
                    fill="#FB0"
                  />
                  <mask
                    id="a"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="204"
                    height="113"
                  >
                    <rect
                      x=".327"
                      y=".656"
                      width="203"
                      height="112"
                      rx="8"
                      fill="#FB0"
                    />
                  </mask>
                  <g mask="url(#a)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M192.578 41.805a.68.68 0 00-.807-.523l-2.882.615-.615-2.882a.68.68 0 10-1.33.284l.615 2.882-2.882.615a.68.68 0 00.284 1.33l2.882-.615.615 2.882a.68.68 0 101.33-.284l-.615-2.882 2.882-.615a.68.68 0 00.523-.807z"
                      fill="#fff"
                    />
                    <path
                      d="M130.608 56.3a48.984 48.984 0 01-5.074-32.576s17.171 6.268 22.773 32.632l-17.699-.056z"
                      fill="#D07A00"
                    />
                    <path
                      d="M143.371 41.895s7.195-25.153 29.395-30.634c0 0 10.896 19.132-3.747 45.162l-20.661-.065s-3.02-10.956-4.987-14.463z"
                      fill="#FF9500"
                    />
                    <path
                      d="M139.917 56.756a.413.413 0 01-.382-.25l-12.596-28.582a.419.419 0 01-.007-.32.42.42 0 01.381-.269.419.419 0 01.394.25l12.595 28.582a.412.412 0 01-.182.541.417.417 0 01-.203.048z"
                      fill="#fff"
                    />
                    <path
                      d="M136.678 49.372h-.062a.418.418 0 01-.345-.314.41.41 0 01-.006-.164l1.058-6.904a.397.397 0 01.162-.28.406.406 0 01.315-.07.412.412 0 01.351.477l-1.057 6.904a.416.416 0 01-.416.35zM133.593 42.463a.427.427 0 01-.207-.052l-5.644-3.28a.418.418 0 11.417-.724l5.644 3.28a.413.413 0 01-.21.776zM154.312 56.8a.428.428 0 01-.156 0 .414.414 0 01-.226-.54l16.491-40.409a.421.421 0 01.545-.23.416.416 0 01.231.543l-16.491 40.41a.417.417 0 01-.394.227z"
                      fill="#fff"
                    />
                    <path
                      d="M159.229 44.748a.412.412 0 01-.413-.353l-2.601-15.781a.4.4 0 01.006-.162.41.41 0 01.337-.314.415.415 0 01.476.344l2.611 15.801a.4.4 0 01-.006.162.402.402 0 01-.186.258.408.408 0 01-.151.056l-.073-.01zM163.821 33.495a.414.414 0 01-.184-.787l8.88-4.498a.42.42 0 11.38.747l-8.879 4.498a.433.433 0 01-.197.04z"
                      fill="#fff"
                    />
                    <path
                      d="M150.184 100.855a22.13 22.13 0 01-22.057-20.182l-2.322-25.225 49.047.156-2.483 25.2a22.13 22.13 0 01-22.185 20.051zm-22.551-43.723l2.152 23.391a20.557 20.557 0 0040.917.13l2.301-23.377-45.37-.144z"
                      fill="#FF9500"
                    />
                    <path
                      d="M150.184 100.855a22.13 22.13 0 01-22.057-20.182l-2.322-25.225 49.047.156-2.483 25.2a22.13 22.13 0 01-22.185 20.051zm-22.551-43.723l2.152 23.391a20.557 20.557 0 0040.917.13l2.301-23.377-45.37-.144z"
                      fill="#FF9500"
                    />
                    <rect
                      x="13.327"
                      y="22.656"
                      width="73"
                      height="10"
                      rx="5"
                      fill="#FF9500"
                    />
                    <rect
                      x="13.327"
                      y="41.656"
                      width="45"
                      height="10"
                      rx="5"
                      fill="#FF9500"
                    />
                    <rect
                      x="13.385"
                      y="73.656"
                      width="82.616"
                      height="24.706"
                      rx="12.353"
                      fill="#FF9500"
                    />
                    <path
                      d="M35.069 89.453l.637-1.905h2.904l.64 1.905h1.494l-2.722-7.724h-1.728l-2.719 7.724h1.494zm1.014-3.029l1.045-3.11h.06l1.045 3.11h-2.15zm7.592 3.13c.995 0 1.444-.592 1.659-1.014h.083v.913h1.342v-7.724h-1.368v2.889h-.057c-.208-.419-.634-1.033-1.656-1.033-1.338 0-2.39 1.048-2.39 2.979 0 1.908 1.021 2.99 2.387 2.99zm.38-1.12c-.9 0-1.376-.792-1.376-1.878 0-1.078.468-1.851 1.376-1.851.88 0 1.362.728 1.362 1.851 0 1.124-.49 1.878-1.362 1.878zm6.309 1.12c.995 0 1.444-.592 1.66-1.014h.082v.913h1.343v-7.724h-1.37v2.889h-.056c-.207-.419-.633-1.033-1.655-1.033-1.34 0-2.391 1.048-2.391 2.979 0 1.908 1.022 2.99 2.387 2.99zm.38-1.12c-.9 0-1.376-.792-1.376-1.878 0-1.078.468-1.851 1.377-1.851.878 0 1.361.728 1.361 1.851 0 1.124-.49 1.878-1.361 1.878zm9.901-6.705v5.43c-.004.815-.38 1.249-1.052 1.249-.626 0-1.045-.38-1.056-1.007h-1.392c0 1.426 1.041 2.157 2.395 2.157 1.49 0 2.49-.905 2.493-2.398v-5.43h-1.388zm5.372 7.837c1.697 0 2.776-1.196 2.776-2.987 0-1.795-1.079-2.994-2.776-2.994-1.697 0-2.775 1.199-2.775 2.994 0 1.791 1.078 2.987 2.775 2.987zm.008-1.094c-.94 0-1.4-.837-1.4-1.897s.46-1.908 1.4-1.908c.924 0 1.384.849 1.384 1.908 0 1.06-.46 1.897-1.384 1.897zm3.987.98h1.343v-.912h.079c.215.422.663 1.014 1.66 1.014 1.364 0 2.386-1.082 2.386-2.99 0-1.931-1.052-2.98-2.39-2.98-1.023 0-1.449.615-1.656 1.034h-.057v-2.889h-1.365v7.724zm1.339-2.896c0-1.123.483-1.851 1.361-1.851.909 0 1.377.773 1.377 1.851 0 1.086-.475 1.878-1.377 1.878-.871 0-1.361-.754-1.361-1.878z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>

              <div className="hero-card card-2" ref={heroCardTwo}>
                <div className="hero-card-inner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 35.8 40.8"
                  >
                    <path
                      fill="#68d3bc"
                      d="M22.6 39.6c8.6-3.3 12.9-12.9 9.6-21.5-3.3-8.6-12.9-12.9-21.5-9.6-8.6 3.3-12.9 13-9.6 21.6 3.3 8.6 13 12.8 21.5 9.5z"
                    />
                    <path d="M29.1 21.2V18.8c0-1.4-.6-2.8-1.6-3.8s-2.5-1.6-3.9-1.6h-9.1c-1.4 0-2.8.6-3.8 1.6s-1.6 2.5-1.6 3.9v2.2c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1v-2.2c0-.9.4-1.7 1-2.4.6-.6 1.4-.9 2.3-1h9c.9 0 1.7.4 2.4 1 .6.6 1 1.4 1 2.3V21.1c0 .6.5 1.1 1.1 1.1.5.1 1-.4 1-1zM24.7 5.4c-.1-3-2.5-5.4-5.6-5.4h-.2c-3 .1-5.4 2.5-5.4 5.6v.2c.1 3 2.5 5.4 5.6 5.4h.2c3-.1 5.4-2.5 5.4-5.6v-.2zm-5.5 3.5c-1.9 0-3.4-1.4-3.4-3.2v-.1c0-1.8 1.4-3.3 3.2-3.3h.1c1.8 0 3.3 1.4 3.3 3.2v.1c0 1.8-1.4 3.2-3.2 3.3zM35.8 21.1V18.8c0-1.2-.4-2.3-1.2-3.3-.8-1-1.8-1.7-3-2-.6-.2-1.2.2-1.4.8-.2.6.2 1.2.8 1.4.7.2 1.3.6 1.8 1.2.4.6.7 1.2.7 1.9V21.1c0 .6.5 1.1 1.1 1.1s1.2-.5 1.2-1.1zM26.6 2.3c.7.2 1.3.6 1.8 1.2.4.6.7 1.2.7 1.9v.1c0 .7-.2 1.5-.7 2-.5.6-1.1 1-1.8 1.2-.6.2-1 .8-.8 1.4.2.6.8 1 1.4.8 1.2-.3 2.3-1 3-2 .8-1 1.2-2.2 1.2-3.4v-.2c0-1.2-.4-2.3-1.2-3.3-.8-1-1.8-1.7-3-2-.7 0-1.3.4-1.4 1s.2 1.2.8 1.3z" />
                  </svg>
                  <h4>Post Job to get best candidates</h4>
                  <p>Start posting</p>
                </div>
              </div>
              <div className="hero-card card-3" ref={heroCardThree}>
                <div className="hero-card-inner">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img src={herocardthumb} alt="" />

                    <div>
                      <span className="line" />
                      <span className="line" />

                      <p>Add Video</p>
                    </div>
                  </Box>
                </div>
              </div>

              <div className="center-hero-card" ref={centerHeroCard}>
                <div style={{display: 'flex', flexWrap: 'nowrap', margin: 0}}>
                  <h3>Hire Best Talent out There :)</h3>
                  <svg
                    width="116"
                    height="141"
                    viewBox="0 0 116 141"
                    id="Layer_1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M74.31 68.329H39.49l8.705 85.519h52.228l8.705-85.52H74.309zM105.704 56.65H42.915v8.64h62.789v-8.64z"
                      fill="#F2B08F"
                    />
                    <path
                      d="M115.316 65.29H33.295v3.04h82.021v-3.04zM42.138 94.337l3.384 33.25h57.574l3.384-33.25H42.138zM60.208 48H58v-1.617c.009-2.274.8-4.455 2.208-6.083 1.573-1.706 2.506-4.027 2.609-6.486 0-2.933-1.517-5.415-2.792-6.966C58.723 25.255 57.999 23.166 58 21h2.208c.007 1.537.527 3.015 1.459 4.137C63.208 27.017 65 30.053 65 33.794c-.1 3.102-1.255 6.04-3.225 8.207-1.01 1.167-1.581 2.73-1.592 4.362L60.208 48zM87.176 49H85v-1.797c.01-2.524.795-4.946 2.192-6.758 1.57-1.895 2.503-4.474 2.608-7.208 0-3.259-1.512-6.016-2.782-7.74C85.72 23.727 84.999 21.407 85 19h2.2c-.005 1.717.505 3.374 1.429 4.638C90.165 25.727 92 29.1 92 33.258c-.098 3.45-1.253 6.717-3.222 9.12-1 1.3-1.566 3.036-1.578 4.846L87.177 49z"
                      fill="#5A625D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.667 43.36a.745.745 0 00-.913-.527l-3.12.836-.835-3.12a.745.745 0 10-1.44.386l.836 3.12-3.12.836a.745.745 0 10.386 1.44l3.12-.836.835 3.12a.746.746 0 001.44-.386l-.836-3.12 3.12-.836a.745.745 0 00.527-.913z"
                      fill="#FF9500"
                    />
                    <path
                      d="M38.386 12.464c.84-.596.487-1.917-.54-2.013l-5.11-.474a1.11 1.11 0 01-.804-.464l-2.966-4.189c-.596-.841-1.917-.487-2.012.54l-.475 5.11c-.03.323-.2.616-.464.804l-4.188 2.966c-.842.596-.488 1.917.539 2.012l5.11.475c.323.03.617.2.804.464l2.966 4.188c.596.842 1.917.488 2.012-.54l.475-5.11c.03-.322.2-.616.464-.803l4.188-2.966z"
                      fill="#FFDF00"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </section>

      <section className="feature" id="feature" style={{position: 'relative'}}>
        <Box sx={{position: 'relative', maxWidth: '1100px', mx: 'auto', my: 6}}>
          <Grid container sx={{position: 'relative'}}>
            <Grid item md={6} sx={{position: 'relative'}}>
              <Box display="flex" flexDirection="column" gap={4}>
                <div>
                  <Typography
                    variant="body2"
                    component="h4"
                    fontSize={15}
                    sx={{
                      backgroundColor: amber[400],
                      display: 'inline-block',
                      fontWeight: 'bold',
                      p: 1,
                      px: 3,
                      borderRadius: '20px',
                      fontSize: '0.8em',
                    }}
                  >
                    Features
                  </Typography>
                </div>
                <Typography variant="h4" component="h3">
                  We Put The "Human" Back In Human Resources.
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  p: 0,
                  my: 5,
                }}
              >
                <div
                  style={{
                    position: 'sticky',
                    top: '90px',
                    width: '100%',
                    paddingBottom: 0,
                    paddingTop: '5rem',
                    borderRadius: '0.8rem',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="landing_feature_img"
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: -1,
                    }}
                  />
                  <img
                    src={featureintro}
                    alt=""
                    style={{width: '100%', height: '100%', marginTop: 3}}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box ml={6} mr={3} pt={18} pb={2}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '400px',
                  }}
                >
                  <h4>
                    <AccessAlarmOutlinedIcon />
                  </h4>
                  <Typography
                    sx={{fontWeight: 'bold'}}
                    fontSize={18}
                    component="h4"
                  >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Tempore maiores tenetur quisquam, fuga ex eaque perferendis
                    enim natus tempora illo consectetur, quod sunt iste eos sint
                    facere? Ut, nulla quam?
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  )
}

export default Landing
