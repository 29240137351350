import React, {useState, useEffect} from 'react'
import {
  ThumbUp,
  FavoriteLight,
  Views,
  Checkmark,
  Yellowbucket,
  Usertyping,
  Userforcontact,
} from '../../../utils/images'
type Props = {
  color: string
  animprop: string
  icontype: string
  animtype?: string
}

const Icons: React.FC<Props> = (props) => {
  const {color, animprop, icontype, ...rest} = props
  const [iconHolder, setIconHolder] = useState([] as any)
  useEffect(() => {
    switch (icontype) {
      case 'thumbup':
        setIconHolder(<ThumbUp />)
        break
      case 'favorite':
        setIconHolder(<FavoriteLight />)
        break
      case 'views':
        setIconHolder(<Views />)
        break
      case 'checkmark':
        setIconHolder(<Checkmark />)
        break
      case 'yellowbucket':
        setIconHolder(<Yellowbucket />)
        break
      case 'Usertyping':
        setIconHolder(<Usertyping />)
        break
      case 'Userforcontact':
        setIconHolder(<Userforcontact />)
        break
    }
  }, [])

  return (
    <>
      {rest?.animtype === 'noanim' ? (
        <div onClick={() => alert('im clicked')} {...rest}>
          {iconHolder}
        </div>
      ) : (
        <div
          className={`svganimation ${color}`}
          onClick={() => alert('im clicked')}
          {...rest}
        >
          {iconHolder}
        </div>
      )}
    </>
  )
}

export default Icons
