import React, {useEffect, useRef} from 'react'
import lottie from 'lottie-web'
import waitloadlottie from './waitloader.json'

type Props = {}

const WaitLoader = (props: Props) => {
  const lotref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    lottie.loadAnimation({
      container: lotref.current,
      animationData: waitloadlottie,
      renderer: 'svg', // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    } as any)
  }, [])
  return (
    <div
      className="relative"
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <div ref={lotref} style={{width: 200, height: 200}} />
    </div>
  )
}

export default WaitLoader
