import React from 'react'

type Props = {}

const ArticleCardOne = (props: Props) => {
  return (
    <div>


      <svg width="251" height="158" viewBox="0 0 251 158" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="251" height="158" rx="12" fill="url(#paint0_linear_102_412)" />
        <path d="M227.769 9H173V49H227.769V9Z" fill="#F4C0A3" />
        <path d="M223.483 12.1384H177.3V45.8616H223.483V12.1384Z" fill="#F6EEE8" fill-opacity="0.78" />
        <path d="M215.545 18.4886H185.238V39.5042H215.545V18.4886Z" fill="white" fill-opacity="0.47" />
        <path d="M202.637 126.154C197.459 126.877 193.833 122.131 195.489 119.681C195.576 119.552 195.683 119.425 195.809 119.301C196.1 118.998 196.584 118.733 197.211 118.534C199.031 117.987 202.701 120.372 204.189 122.629L204.262 122.741C205.263 124.315 205.177 125.799 202.637 126.154Z" fill="#8EBF8C" />
        <path d="M206.112 131.903C206.083 131.903 206.056 131.899 206.033 131.892C206.01 131.885 205.994 131.875 205.986 131.864C205.937 131.796 201.096 125.016 199.67 122.912C199.667 122.899 199.676 122.886 199.693 122.876C199.711 122.865 199.737 122.858 199.767 122.854C199.797 122.851 199.829 122.852 199.857 122.858C199.885 122.863 199.908 122.872 199.922 122.884C201.344 124.989 206.182 131.768 206.234 131.836C206.24 131.843 206.242 131.85 206.24 131.857C206.239 131.864 206.234 131.871 206.225 131.877C206.217 131.884 206.206 131.889 206.193 131.894C206.179 131.898 206.164 131.901 206.147 131.903L206.112 131.903Z" fill="black" />
        <path d="M204.252 122.742C200.889 123.283 197.81 121.058 195.798 119.301C196.089 118.998 196.574 118.733 197.2 118.534C199.021 117.987 202.691 120.373 204.179 122.629L204.252 122.742Z" fill="black" />
        <path d="M210.348 132.697C210.314 132.696 210.282 132.691 210.258 132.681C210.234 132.671 210.22 132.658 210.219 132.644C210.219 132.567 209.287 124.85 209.533 120.812C209.532 120.805 209.535 120.798 209.541 120.791C209.548 120.784 209.557 120.778 209.57 120.773C209.583 120.768 209.598 120.764 209.614 120.762C209.631 120.759 209.649 120.759 209.666 120.759C209.701 120.76 209.734 120.766 209.758 120.776C209.782 120.786 209.795 120.8 209.795 120.814C209.549 124.846 210.471 132.56 210.481 132.638C210.482 132.652 210.469 132.666 210.446 132.677C210.422 132.687 210.39 132.693 210.355 132.694L210.348 132.697Z" fill="black" />
        <path d="M203.334 123.13C207.135 122.499 204.98 109.683 199.352 110.038C192.345 110.483 197.669 124.072 203.334 123.13Z" fill="#8EBF8C" />
        <path d="M215.554 127.468L215.509 127.457C214.764 127.277 214.374 126.925 214.274 126.471C213.824 124.437 219.127 120.327 223.958 120.097C224.739 120.057 225.531 120.131 226.199 120.305C226.525 120.39 226.824 120.491 227.089 120.605C231.101 122.313 219.439 128.362 215.554 127.468Z" fill="#8EBF8C" />
        <path d="M223.957 120.097C217.719 121.848 216.246 124.799 214.274 126.471C213.824 124.437 219.127 120.327 223.957 120.097Z" fill="black" />
        <path d="M212.54 130.279L212.498 130.279C212.465 130.274 212.438 130.263 212.423 130.251C212.408 130.238 212.406 130.223 212.417 130.209C215.228 126.891 220.517 123.554 221.806 122.947C221.816 122.94 221.83 122.935 221.846 122.931C221.862 122.926 221.88 122.924 221.899 122.922C221.918 122.921 221.937 122.922 221.956 122.924C221.974 122.926 221.991 122.93 222.005 122.935C222.02 122.941 222.031 122.947 222.039 122.954C222.047 122.961 222.051 122.969 222.051 122.977C222.051 122.985 222.047 122.993 222.039 123C222.031 123.007 222.02 123.013 222.005 123.018C220.723 123.621 215.479 126.941 212.666 130.245C212.656 130.255 212.639 130.264 212.616 130.27C212.593 130.276 212.567 130.279 212.54 130.279V130.279Z" fill="black" />
        <path d="M217.671 104.29C217.467 105.108 217.205 105.983 216.885 106.916C215.329 111.406 214.014 114.167 212.818 115.82C211.433 117.742 210.208 118.166 208.963 118.069C208.47 118.03 207.995 117.825 207.566 117.488C205.185 115.648 203.7 109.859 205.251 104.717C206.937 99.1355 212.245 96.6024 215.719 97.1471C215.825 97.1635 215.927 97.1846 216.023 97.2102C218.126 97.7482 218.641 100.303 217.671 104.29Z" fill="#4B764A" />
        <path d="M210.075 117.224C210.042 117.223 210.011 117.218 209.988 117.209C209.964 117.2 209.949 117.188 209.946 117.174C208.81 112.235 210.246 104.333 212.9 100.912C212.905 100.906 212.914 100.899 212.925 100.894C212.936 100.889 212.949 100.884 212.965 100.881C212.98 100.878 212.997 100.876 213.014 100.876C213.031 100.875 213.048 100.876 213.064 100.878C213.081 100.881 213.096 100.884 213.109 100.889C213.122 100.893 213.132 100.899 213.14 100.905C213.148 100.912 213.153 100.919 213.154 100.926C213.155 100.933 213.153 100.94 213.148 100.946C210.502 104.354 209.073 112.234 210.204 117.162C210.206 117.17 210.205 117.177 210.2 117.184C210.195 117.19 210.186 117.197 210.175 117.202C210.164 117.208 210.15 117.212 210.135 117.215C210.12 117.219 210.103 117.221 210.085 117.221L210.075 117.224Z" fill="black" />
        <path d="M217.67 104.29C217.467 105.108 217.205 105.983 216.884 106.915C215.328 111.405 214.013 114.166 212.818 115.819L210.419 115.755C210.322 111.891 211.481 107.093 217.67 104.29Z" fill="black" />
        <path d="M214.093 125.53C213.137 125.912 212.28 125.998 211.549 125.837C209.544 125.397 208.458 123.119 208.693 120.066C209.208 113.36 214.062 107.196 219.115 106.217C219.556 106.123 220.049 106.077 220.547 106.082C221.045 106.087 221.532 106.144 221.965 106.247C225.295 107.008 223.084 121.934 214.093 125.53Z" fill="#4B764A" />
        <path d="M211.87 131.577C211.837 131.577 211.805 131.571 211.781 131.561C211.757 131.552 211.743 131.539 211.741 131.525C211.175 123.049 215.94 110.803 218.125 108.231C218.136 108.217 218.161 108.206 218.192 108.2C218.224 108.194 218.26 108.193 218.293 108.198C218.326 108.203 218.353 108.213 218.368 108.226C218.383 108.239 218.384 108.254 218.373 108.268C216.177 110.834 211.427 123.057 211.989 131.523C211.99 131.537 211.977 131.551 211.954 131.561C211.93 131.572 211.898 131.578 211.863 131.579L211.87 131.577Z" fill="black" />
        <path d="M208.411 132.54C208.381 132.54 208.351 132.536 208.328 132.528C208.304 132.52 208.288 132.509 208.282 132.497C208.219 132.374 202.003 120.088 199.703 113.207C199.701 113.2 199.702 113.193 199.706 113.186C199.711 113.179 199.718 113.172 199.729 113.167C199.739 113.161 199.752 113.157 199.767 113.153C199.782 113.15 199.799 113.147 199.816 113.147C199.833 113.145 199.85 113.146 199.867 113.148C199.884 113.149 199.9 113.152 199.913 113.157C199.927 113.161 199.939 113.167 199.947 113.173C199.955 113.179 199.961 113.186 199.962 113.193C202.258 120.074 208.475 132.352 208.537 132.476C208.543 132.49 208.536 132.504 208.517 132.516C208.498 132.528 208.469 132.536 208.436 132.539L208.411 132.54Z" fill="black" />
        <path d="M196.022 129.361L223.055 129.464L216.578 143.108L202.135 143.052L196.022 129.361Z" fill="#E4D0C0" />
        <path d="M220.869 134.139L220.823 134.139C220.701 134.124 208.617 132.72 197.468 132.669C197.422 132.668 197.377 132.661 197.344 132.647C197.312 132.633 197.293 132.615 197.293 132.596C197.293 132.577 197.312 132.559 197.345 132.545C197.378 132.532 197.422 132.524 197.469 132.525C208.659 132.576 220.796 133.986 220.918 133.999C220.965 134.002 221.007 134.012 221.035 134.028C221.064 134.043 221.076 134.062 221.07 134.081C221.064 134.1 221.04 134.118 221.003 134.129C220.966 134.141 220.919 134.146 220.872 134.143L220.869 134.139Z" fill="#F6EEE8" />
        <path d="M200.729 135.856C200.065 135.854 199.442 135.852 198.876 135.834C198.853 135.833 198.83 135.831 198.809 135.827C198.789 135.823 198.77 135.817 198.754 135.81C198.739 135.803 198.727 135.795 198.719 135.786C198.711 135.777 198.707 135.767 198.708 135.758C198.709 135.749 198.714 135.739 198.723 135.731C198.733 135.722 198.746 135.714 198.763 135.708C198.78 135.701 198.801 135.696 198.822 135.693C198.844 135.69 198.867 135.689 198.89 135.69C205.573 135.84 220.127 135.213 220.274 135.207C220.319 135.205 220.365 135.211 220.401 135.223C220.436 135.235 220.46 135.252 220.466 135.271C220.471 135.29 220.457 135.309 220.428 135.323C220.399 135.338 220.358 135.347 220.312 135.349C220.179 135.356 207.94 135.884 200.729 135.856Z" fill="#F6EEE8" />
        <path d="M216.933 137.43C209.62 137.403 199.324 136.643 199.208 136.633C199.162 136.629 199.121 136.618 199.094 136.602C199.067 136.587 199.056 136.567 199.064 136.548C199.072 136.529 199.098 136.512 199.136 136.501C199.174 136.49 199.222 136.485 199.268 136.489C199.398 136.498 212.159 137.442 219.304 137.262C219.327 137.262 219.35 137.263 219.372 137.266C219.394 137.269 219.414 137.274 219.431 137.28C219.449 137.287 219.463 137.294 219.473 137.303C219.483 137.311 219.49 137.321 219.491 137.33C219.493 137.34 219.49 137.349 219.482 137.358C219.475 137.367 219.463 137.375 219.448 137.382C219.433 137.389 219.415 137.395 219.394 137.399C219.374 137.403 219.351 137.406 219.328 137.407C218.583 137.428 217.779 137.434 216.933 137.43Z" fill="#F6EEE8" />
        <path d="M153.401 23H22.5456C20.0351 23 18 25.0351 18 27.5456V122.852C18 125.363 20.0351 127.398 22.5456 127.398H153.401C155.911 127.398 157.946 125.363 157.946 122.852V27.5456C157.946 25.0351 155.911 23 153.401 23Z" fill="white" />
        <path d="M146.518 43.9356H91.2745V84.7177H146.518V43.9356Z" fill="#D378EA" fill-opacity="0.66" />
        <path d="M118.896 55.2278H109.692V84.7176H118.896V55.2278Z" fill="#E4D0C0" />
        <path d="M109.692 55.2278H100.487V84.7176H109.692V55.2278Z" fill="#E4D0C0" />
        <path d="M100.479 55.2278H91.2745V84.7176H100.479V55.2278Z" fill="#E4D0C0" />
        <path d="M146.518 62.6021H91.2745V69.9764H146.518V62.6021Z" fill="#D378EA" fill-opacity="0.92" />
        <path d="M146.518 69.9765H91.2745V77.3508H146.518V69.9765Z" fill="#E4D0C0" />
        <path d="M109.692 85.0958C109.591 85.0958 109.495 85.0559 109.424 84.985C109.353 84.9141 109.313 84.8179 109.313 84.7176V55.2202C109.313 55.1199 109.353 55.0237 109.424 54.9528C109.495 54.8819 109.591 54.842 109.692 54.842C109.792 54.842 109.888 54.8819 109.959 54.9528C110.03 55.0237 110.07 55.1199 110.07 55.2202V84.7176C110.07 84.8179 110.03 84.9141 109.959 84.985C109.888 85.0559 109.792 85.0958 109.692 85.0958Z" fill="#F6EEE8" />
        <path d="M100.494 85.0958C100.394 85.0958 100.298 85.0559 100.227 84.985C100.156 84.9141 100.116 84.8179 100.116 84.7176V55.2202C100.116 55.1199 100.156 55.0237 100.227 54.9528C100.298 54.8819 100.394 54.842 100.494 54.842C100.595 54.842 100.691 54.8819 100.762 54.9528C100.833 55.0237 100.872 55.1199 100.872 55.2202V84.7176C100.872 84.8179 100.833 84.9141 100.762 84.985C100.691 85.0559 100.595 85.0958 100.494 85.0958Z" fill="#F6EEE8" />
        <path d="M109.692 85.0958C109.591 85.0958 109.495 85.0559 109.424 84.985C109.353 84.9141 109.313 84.8179 109.313 84.7176V55.2202C109.313 55.1199 109.353 55.0237 109.424 54.9528C109.495 54.8819 109.591 54.842 109.692 54.842C109.792 54.842 109.888 54.8819 109.959 54.9528C110.03 55.0237 110.07 55.1199 110.07 55.2202V84.7176C110.07 84.8179 110.03 84.9141 109.959 84.985C109.888 85.0559 109.792 85.0958 109.692 85.0958Z" fill="#F6EEE8" />
        <path d="M100.494 85.0958C100.394 85.0958 100.298 85.0559 100.227 84.985C100.156 84.9141 100.116 84.8179 100.116 84.7176V55.2202C100.116 55.1199 100.156 55.0237 100.227 54.9528C100.298 54.8819 100.394 54.842 100.494 54.842C100.595 54.842 100.691 54.8819 100.762 54.9528C100.833 55.0237 100.872 55.1199 100.872 55.2202V84.7176C100.872 84.8179 100.833 84.9141 100.762 84.985C100.691 85.0559 100.595 85.0958 100.494 85.0958Z" fill="#F6EEE8" />
        <path d="M146.518 62.9878H91.3049C91.2046 62.9878 91.1084 62.948 91.0375 62.8771C90.9666 62.8062 90.9268 62.71 90.9268 62.6097C90.9268 62.5094 90.9666 62.4132 91.0375 62.3423C91.1084 62.2714 91.2046 62.2315 91.3049 62.2315H146.518C146.618 62.2315 146.714 62.2714 146.785 62.3423C146.856 62.4132 146.896 62.5094 146.896 62.6097C146.896 62.71 146.856 62.8062 146.785 62.8771C146.714 62.948 146.618 62.9878 146.518 62.9878Z" fill="#F6EEE8" />
        <path d="M146.518 70.3546H91.3049C91.2046 70.3546 91.1084 70.3148 91.0375 70.2439C90.9666 70.173 90.9268 70.0768 90.9268 69.9765C90.9268 69.8762 90.9666 69.78 91.0375 69.7091C91.1084 69.6381 91.2046 69.5983 91.3049 69.5983H146.518C146.618 69.5983 146.714 69.6381 146.785 69.7091C146.856 69.78 146.896 69.8762 146.896 69.9765C146.896 70.0768 146.856 70.173 146.785 70.2439C146.714 70.3148 146.618 70.3546 146.518 70.3546Z" fill="#F6EEE8" />
        <path d="M146.518 70.3546H91.3049C91.2046 70.3546 91.1084 70.3148 91.0375 70.2439C90.9666 70.173 90.9268 70.0768 90.9268 69.9765C90.9268 69.8762 90.9666 69.78 91.0375 69.7091C91.1084 69.6381 91.2046 69.5983 91.3049 69.5983H146.518C146.618 69.5983 146.714 69.6381 146.785 69.7091C146.856 69.78 146.896 69.8762 146.896 69.9765C146.896 70.0768 146.856 70.173 146.785 70.2439C146.714 70.3148 146.618 70.3546 146.518 70.3546Z" fill="#F6EEE8" />
        <path d="M146.518 77.7214H91.3049C91.2046 77.7214 91.1084 77.6815 91.0375 77.6106C90.9666 77.5397 90.9268 77.4435 90.9268 77.3432C90.9268 77.2429 90.9666 77.1467 91.0375 77.0758C91.1084 77.0049 91.2046 76.965 91.3049 76.965H146.518C146.618 76.965 146.714 77.0049 146.785 77.0758C146.856 77.1467 146.896 77.2429 146.896 77.3432C146.896 77.4435 146.856 77.5397 146.785 77.6106C146.714 77.6815 146.618 77.7214 146.518 77.7214Z" fill="#F6EEE8" />
        <path d="M88.5064 142.487C87.4929 142.82 86.139 142.064 86.2373 140.218C86.3356 138.373 86.3508 134.409 85.5188 133.411C85.5188 133.411 89.482 131.997 92.4998 132.556C92.4998 132.556 93.2562 140.876 88.7181 142.449" fill="black" />
        <path d="M32.8091 109.193C31.1754 106.576 34.594 103.324 36.3034 99.9806C37.1885 98.0648 37.7728 96.0239 38.0354 93.9299L43.655 106.266C43.655 106.266 36.0765 114.419 32.8091 109.193Z" fill="black" />
        <path d="M68.1833 71.7992C66.9959 72.4346 59.0089 76.6171 52.9127 79.8165L45.7956 83.5452C44.2829 81.4729 42.634 78.803 40.8945 75.8835C34.6017 65.2947 27.6584 51.5065 27.6584 51.5065C27.6584 51.5065 39.6465 45.2894 50.6286 40.2824C59.5005 57.7691 68.1833 71.7992 68.1833 71.7992Z" fill="#369466" />
        <path d="M50.1218 96.267C48.1992 101.137 45.7707 105.791 42.876 110.153C39.049 101.607 32.4688 84.0218 31.8032 81.4956C30.8199 77.8198 31.4628 74.5221 34.3294 68.2596C36.3412 63.8577 37.1051 63.6837 41.0759 61.6643C45.0467 59.6449 48.1402 58.1624 48.42 58.5784C49.0251 59.4633 48.3217 60.9231 42.0516 64.9393C44.519 64.4572 47.0255 64.2041 49.5394 64.1829C51.4832 64.1073 49.7588 66.3385 48.3822 66.5654C50.0235 67.0948 48.2839 69.2277 46.6275 69.7193C48.42 69.916 47.8377 71.4438 45.7048 72.7447C44.5097 73.4557 42.5886 74.7112 41.7869 75.2407H41.7037L40.8944 75.9365C42.634 78.856 44.3207 81.5259 45.7955 83.5983L49.842 81.4805L50.8328 83.7495C51.8884 87.8959 51.6402 92.2669 50.1218 96.267Z" fill="#FFC36C" />
        <path d="M75.0663 82.4032C75.5655 78.2055 77.9555 77.0256 80.1262 77.5551C82.9398 78.2433 81.6389 71.7766 90.2007 74.9457L89.5351 82.5621L75.0663 82.4032Z" fill="#C15D31" />
        <path d="M98.8911 59.2818C96.0472 56.0522 90.2158 56.0371 87.0694 59.9398C84.3012 63.3661 85.0273 68.6832 87.4552 72.8582C87.4476 72.8582 103.429 64.4325 98.8911 59.2818Z" fill="#C15D31" />
        <path d="M93.0294 80.1114C107.823 80.4518 115.478 79.5895 120.001 88.9001C124.524 98.2107 134.265 122.656 131.399 128.442C128.532 134.228 92.3411 142.495 88.5064 142.495C88.5064 142.495 90.9721 140.347 91.2066 138.274C91.5394 135.249 92.4848 128.578 87.977 118.133L76.1024 127.398H66.5422V118.685C66.5422 118.685 63.948 125 61.1571 127.761C58.941 129.924 50.8935 129.879 47.437 126.604C43.9805 123.329 33.6489 111.341 32.8169 109.208C32.8169 109.208 34.738 110.04 37.6877 108.694C40.6375 107.347 51.9902 89.2027 50.833 83.6814L55.1971 96.9552C55.1971 96.9552 58.8502 90.0044 60.6049 87.4026C63.2521 83.4772 69.1289 80.6636 76.7529 80.3762C84.6113 80.0736 86.0483 79.9526 93.0294 80.1114Z" fill="#7B7AB7" />
        <path d="M91.5241 133.88C87.5003 133.933 84.717 134.863 83.8774 136.225C82.3818 139.065 81.0926 142.01 80.0201 145.036C79.1427 147.57 78.1897 151.2 76.5712 150.466C76.0795 152.516 74.3021 152.683 74.113 149.914C74.113 149.914 72.7365 151.79 71.7911 151.427C70.2103 150.784 70.2784 143.274 70.2784 143.274C70.2784 143.274 68.3421 146.118 67.5253 145.913C66.7084 145.709 65.9597 145.043 68.9472 137.299C70.2069 133.914 71.6797 130.612 73.3567 127.413C74.4523 125.242 75.8987 123.267 77.6376 121.567C81.1319 118.398 84.5279 118.541 87.9693 118.148C90.9417 125.008 91.5543 130.234 91.5241 133.88Z" fill="#FFC36C" />
        <path d="M97.7944 80.6863C97.7944 80.6863 97.3859 82.6301 97.091 84.7176C97.0456 84.9899 97.0078 85.2546 96.9775 85.5269C96.622 88.4464 93.695 89.7473 90.7755 88.7867C87.856 87.8262 86.9938 85.905 87.4325 84.3167C87.8711 82.7284 89.0283 75.1877 89.0283 75.1877C84.5735 75.1877 85.3903 68.7739 88.8468 70.7177C89.2855 70.1355 89.5243 69.4272 89.5275 68.6982C88.9149 64.6896 90.0418 61.1348 91.2823 61.1348C91.9932 61.1348 94.353 62.3374 96.622 61.1348C98.8911 59.9322 98.8911 59.2818 98.8911 59.2818C98.8911 59.2818 101.47 61.7399 103.293 68.7966C105.116 75.8532 103.679 80.9359 97.7944 80.6863Z" fill="#FFC36C" />
        <path d="M97.7942 80.6862C97.7942 80.6862 97.3858 82.6301 97.0908 84.7176C94.8529 83.2626 93.2356 81.0279 92.5527 78.4475C94.0925 79.6077 95.8914 80.376 97.7942 80.6862V80.6862Z" fill="black" />
        <path d="M99.1934 74.3784C98.2579 74.3781 97.3356 74.1579 96.5009 73.7355C96.4473 73.6953 96.4094 73.6377 96.3939 73.5725C96.3783 73.5073 96.386 73.4388 96.4156 73.3787C96.4452 73.3186 96.4948 73.2707 96.556 73.2433C96.6171 73.216 96.6859 73.2108 96.7505 73.2288C97.71 73.7136 98.7918 73.9031 99.859 73.7734C99.9343 73.7663 100.009 73.7895 100.067 73.8377C100.125 73.8859 100.162 73.9553 100.169 74.0305C100.176 74.1057 100.153 74.1807 100.105 74.2388C100.057 74.297 99.9872 74.3336 99.912 74.3406C99.6926 74.333 99.4582 74.3784 99.1934 74.3784Z" fill="black" />
        <path d="M89.0585 73.1456C89.0235 73.1525 88.9876 73.1525 88.9526 73.1456L87.3492 72.4724C87.28 72.4433 87.2251 72.388 87.1968 72.3185C87.1684 72.2489 87.1688 72.171 87.1979 72.1018C87.227 72.0326 87.2824 71.9778 87.3519 71.9494C87.4214 71.9211 87.4993 71.9215 87.5685 71.9506L89.1719 72.6237C89.2362 72.6453 89.2904 72.6896 89.3245 72.7482C89.3586 72.8068 89.3703 72.8758 89.3573 72.9423C89.3444 73.0089 89.3077 73.0685 89.2542 73.11C89.2006 73.1516 89.1337 73.1723 89.0661 73.1683L89.0585 73.1456Z" fill="black" />
        <path d="M94.0201 67.5864C93.9802 67.595 93.939 67.595 93.8991 67.5864C93.8315 67.5537 93.7794 67.4957 93.7539 67.4251C93.7285 67.3544 93.7317 67.2765 93.763 67.2082C94.5193 65.5897 95.7521 64.7804 96.5312 64.8409C96.6047 64.8485 96.6725 64.8842 96.7204 64.9406C96.7683 64.9969 96.7926 65.0696 96.7883 65.1434C96.7855 65.1802 96.7754 65.2161 96.7586 65.2489C96.7418 65.2818 96.7187 65.311 96.6906 65.3349C96.6625 65.3588 96.6299 65.3769 96.5947 65.3882C96.5596 65.3995 96.5226 65.4037 96.4858 65.4006C96.0093 65.3476 94.9731 65.9829 94.2697 67.4427C94.2444 67.4865 94.208 67.5228 94.1642 67.5481C94.1204 67.5733 94.0707 67.5865 94.0201 67.5864Z" fill="black" />
        <path d="M101.115 72.4725C101.059 72.47 101.005 72.4512 100.959 72.4185C100.914 72.3859 100.879 72.3407 100.859 72.2885C100.839 72.2363 100.835 72.1794 100.846 72.1248C100.858 72.0701 100.886 72.0201 100.925 71.9808C101.107 71.8069 101.614 71.2245 101.515 70.8236C101.358 70.5393 101.153 70.2836 100.91 70.0673C99.9085 69.1978 99.2203 68.0232 98.9514 66.7243C98.8516 65.9393 99.0634 65.1464 99.5413 64.5157C99.7751 64.1997 100.069 63.9334 100.407 63.7321C100.744 63.5308 101.119 63.3987 101.508 63.3434C101.582 63.3334 101.657 63.3532 101.717 63.3986C101.776 63.444 101.815 63.5112 101.825 63.5854C101.836 63.6596 101.816 63.7348 101.77 63.7944C101.725 63.854 101.658 63.8931 101.583 63.9031C101.268 63.9481 100.964 64.0556 100.69 64.2192C100.416 64.3828 100.177 64.5993 99.9876 64.8561C99.5958 65.3662 99.4218 66.0106 99.5035 66.6486C99.7568 67.8285 100.392 68.8919 101.311 69.674C101.639 69.9391 101.898 70.2789 102.068 70.6648C102.272 71.4741 101.41 72.2909 101.311 72.3817C101.286 72.4091 101.256 72.4313 101.222 72.4469C101.188 72.4625 101.152 72.4711 101.115 72.4725V72.4725Z" fill="black" />
        <path d="M97.0118 69.4265C97.2598 69.3061 97.2907 68.8581 97.0809 68.426C96.8711 67.9939 96.4999 67.7412 96.2519 67.8616C96.0039 67.9821 95.973 68.43 96.1828 68.8621C96.3926 69.2942 96.7638 69.5469 97.0118 69.4265Z" fill="black" />
        <path d="M101.47 68.0808C101.718 67.9604 101.749 67.5124 101.539 67.0803C101.329 66.6482 100.958 66.3955 100.71 66.5159C100.462 66.6364 100.431 67.0843 100.641 67.5164C100.851 67.9485 101.222 68.2012 101.47 68.0808Z" fill="black" />
        <path d="M66.5422 118.957C66.4999 118.967 66.4559 118.967 66.4136 118.957C66.3473 118.923 66.2969 118.865 66.2729 118.794C66.2489 118.724 66.2532 118.646 66.285 118.579C68.433 114.223 73.2887 103.989 73.3341 103.883C73.3724 103.826 73.4299 103.785 73.4962 103.766C73.5625 103.748 73.6332 103.754 73.6954 103.783C73.7577 103.812 73.8074 103.863 73.8355 103.926C73.8635 103.989 73.8681 104.059 73.8484 104.125C73.8031 104.231 68.9398 114.465 66.7918 118.829C66.7646 118.87 66.7274 118.903 66.6837 118.925C66.64 118.948 66.5913 118.959 66.5422 118.957V118.957Z" fill="black" />
        <path d="M57.3148 102.824C57.2606 102.824 57.2077 102.808 57.1622 102.778C57.1167 102.749 57.0805 102.707 57.0577 102.658C57.0577 102.658 56.1576 100.631 54.9323 97.0384C54.9216 96.9713 54.9349 96.9026 54.97 96.8444C55.0051 96.7862 55.0597 96.7423 55.1241 96.7206C55.1885 96.6988 55.2585 96.7006 55.3217 96.7255C55.3849 96.7505 55.4372 96.797 55.4694 96.8569C56.6871 100.427 57.572 102.408 57.5796 102.431C57.6103 102.5 57.6124 102.579 57.5855 102.65C57.5586 102.721 57.5048 102.778 57.4359 102.809C57.397 102.823 57.3558 102.828 57.3148 102.824V102.824Z" fill="black" />
        <path d="M87.9694 118.413C87.8942 118.413 87.8221 118.383 87.7689 118.33C87.7157 118.276 87.6858 118.204 87.6858 118.129C87.6858 118.054 87.7157 117.982 87.7689 117.928C87.8221 117.875 87.8942 117.845 87.9694 117.845C91.1688 117.475 95.374 117.089 99.4431 116.771C105.736 116.234 112.868 115.629 113.557 115.138C113.587 115.116 113.621 115.1 113.657 115.092C113.694 115.083 113.731 115.082 113.768 115.088C113.805 115.094 113.841 115.107 113.872 115.126C113.904 115.146 113.932 115.172 113.954 115.202C113.975 115.232 113.991 115.267 114 115.303C114.008 115.339 114.01 115.377 114.004 115.414C113.998 115.451 113.985 115.486 113.965 115.518C113.945 115.55 113.92 115.577 113.889 115.599C113.133 116.151 107.914 116.62 99.5188 117.331C95.4572 117.679 91.2519 118.034 88.0602 118.398L87.9694 118.413Z" fill="black" />
        <path d="M109.298 116.431H109.23C109.157 116.411 109.095 116.364 109.057 116.299C109.019 116.234 109.007 116.156 109.026 116.083C112.135 103.626 112.808 100.268 112.808 98.1124C112.801 98.0713 112.803 98.0292 112.814 97.989C112.826 97.9488 112.845 97.9116 112.872 97.8798C112.899 97.8481 112.933 97.8226 112.971 97.8051C113.008 97.7876 113.05 97.7785 113.091 97.7785C113.133 97.7785 113.174 97.7876 113.212 97.8051C113.25 97.8226 113.283 97.8481 113.31 97.8798C113.337 97.9116 113.357 97.9488 113.368 97.989C113.379 98.0292 113.382 98.0713 113.375 98.1124C113.435 100.381 112.762 103.573 109.593 116.265C109.569 116.321 109.527 116.367 109.474 116.398C109.42 116.428 109.359 116.439 109.298 116.431V116.431Z" fill="black" />
        <path d="M76.5713 150.724C76.2536 150.724 75.5956 150.648 76.3066 138.479C76.3074 138.441 76.3161 138.403 76.3321 138.369C76.3481 138.335 76.3711 138.304 76.3995 138.279C76.428 138.255 76.4613 138.236 76.4974 138.225C76.5335 138.213 76.5715 138.21 76.6091 138.214C76.6466 138.216 76.6833 138.225 76.717 138.241C76.7508 138.258 76.7809 138.281 76.8056 138.309C76.8303 138.337 76.849 138.37 76.8608 138.406C76.8725 138.441 76.8769 138.479 76.8739 138.516C76.5713 143.697 76.4276 149.491 76.7377 150.209C76.7866 150.244 76.8235 150.293 76.8432 150.349C76.863 150.406 76.8647 150.467 76.8481 150.524C76.8314 150.582 76.7973 150.632 76.7504 150.67C76.7035 150.707 76.6462 150.728 76.5864 150.731L76.5713 150.724Z" fill="black" />
        <path d="M74.1132 150.202C74.0456 150.201 73.9804 150.176 73.9292 150.132C73.878 150.087 73.8441 150.027 73.8334 149.96C73.2359 145.807 73.0317 139.605 73.8334 136.277C73.8514 136.204 73.8978 136.141 73.9624 136.102C74.0269 136.063 74.1043 136.051 74.1775 136.069C74.2507 136.088 74.3138 136.134 74.3528 136.198C74.3918 136.263 74.4036 136.34 74.3855 136.414C73.6292 139.673 73.7956 145.785 74.3855 149.884C74.3908 149.921 74.3888 149.958 74.3794 149.994C74.3701 150.03 74.3537 150.064 74.3311 150.094C74.3086 150.123 74.2804 150.148 74.2482 150.167C74.216 150.185 74.1804 150.197 74.1435 150.202H74.1132Z" fill="black" />
        <path d="M70.2786 143.538C70.2419 143.538 70.2055 143.531 70.1715 143.517C70.1376 143.503 70.1067 143.482 70.0808 143.456C70.0548 143.43 70.0341 143.4 70.0201 143.366C70.006 143.332 69.9988 143.295 69.9988 143.259C69.9988 139.802 70.8459 135.166 71.6098 133.335C71.6242 133.301 71.6452 133.27 71.6716 133.244C71.698 133.218 71.7294 133.197 71.7638 133.183C71.7982 133.169 71.835 133.162 71.8722 133.162C71.9094 133.162 71.9461 133.17 71.9804 133.184C72.0147 133.198 72.0458 133.22 72.0719 133.246C72.0981 133.272 72.1187 133.304 72.1328 133.338C72.1468 133.372 72.154 133.409 72.1538 133.446C72.1536 133.484 72.1461 133.52 72.1317 133.555C71.1128 136.689 70.5847 139.963 70.566 143.259C70.567 143.296 70.5606 143.333 70.5469 143.368C70.5333 143.403 70.5129 143.435 70.4868 143.461C70.4607 143.488 70.4295 143.509 70.3951 143.524C70.3606 143.539 70.3236 143.546 70.2862 143.546L70.2786 143.538Z" fill="black" />
        <path d="M37.9675 66.9663C37.903 66.9705 37.8391 66.9523 37.7865 66.9148C37.734 66.8773 37.696 66.8227 37.6791 66.7604C37.6622 66.698 37.6673 66.6318 37.6937 66.5728C37.7201 66.5138 37.766 66.4658 37.8238 66.4369C39.1356 65.6814 40.5387 65.0967 41.9988 64.6973C42.0356 64.6908 42.0732 64.6917 42.1096 64.6998C42.1461 64.7079 42.1805 64.7231 42.2111 64.7445C42.2416 64.7659 42.2677 64.7931 42.2877 64.8246C42.3077 64.8561 42.3214 64.8912 42.3278 64.928C42.3343 64.9647 42.3334 65.0024 42.3253 65.0388C42.3172 65.0752 42.302 65.1097 42.2806 65.1403C42.2592 65.1708 42.232 65.1968 42.2005 65.2169C42.169 65.2369 42.1339 65.2505 42.0971 65.257C40.702 65.6504 39.3607 66.2143 38.1036 66.9361C38.0615 66.9575 38.0147 66.9679 37.9675 66.9663V66.9663Z" fill="black" />
        <path d="M38.27 70.3471C38.2004 70.3568 38.1297 70.3399 38.0719 70.2997C38.0142 70.2596 37.9737 70.1993 37.9584 70.1307C37.9432 70.062 37.9543 69.9902 37.9896 69.9294C38.0249 69.8686 38.0818 69.8233 38.149 69.8026C38.4818 69.6513 46.4082 66.0209 48.4125 66.2856C48.4501 66.2893 48.4865 66.3006 48.5196 66.3187C48.5527 66.3369 48.5818 66.3615 48.6051 66.3912C48.6284 66.4209 48.6455 66.4549 48.6553 66.4914C48.6651 66.5278 48.6674 66.5659 48.6621 66.6032C48.6527 66.6783 48.614 66.7465 48.5546 66.7933C48.4951 66.84 48.4196 66.8614 48.3445 66.8528C46.8318 66.6486 40.6373 69.2958 38.3834 70.3245C38.3476 70.3398 38.309 70.3476 38.27 70.3471V70.3471Z" fill="black" />
        <path d="M38.5197 73.5767C38.4553 73.5809 38.3914 73.5627 38.3388 73.5252C38.2862 73.4876 38.2482 73.4331 38.2313 73.3707C38.2144 73.3084 38.2196 73.2421 38.246 73.1831C38.2723 73.1242 38.3183 73.0762 38.376 73.0472C41.53 71.2622 45.0243 69.3789 46.6353 69.447C46.7102 69.4509 46.7806 69.484 46.8315 69.5391C46.8824 69.5942 46.9097 69.667 46.9076 69.742C46.9066 69.7794 46.8982 69.8163 46.8827 69.8503C46.8673 69.8844 46.8452 69.9151 46.8176 69.9405C46.7901 69.9659 46.7578 69.9855 46.7226 69.9981C46.6874 70.0108 46.65 70.0163 46.6126 70.0143C45.0545 69.9235 40.8795 72.2833 38.6559 73.5464C38.6138 73.5678 38.5669 73.5782 38.5197 73.5767V73.5767Z" fill="black" />
        <path d="M41.4241 87.9472C41.3745 87.9475 41.3256 87.9346 41.2826 87.9099C41.2396 87.8851 41.2039 87.8493 41.1793 87.8062C41.1546 87.7631 41.1419 87.7142 41.1424 87.6645C41.1429 87.6149 41.1566 87.5662 41.1821 87.5236C42.6948 84.8689 41.1821 81.5712 40.2972 79.8014L40.1308 79.4534C39.2534 77.5777 39.5257 76.5642 41.2578 75.2557C41.3182 75.2242 41.3879 75.2152 41.4543 75.2302C41.5208 75.2453 41.5798 75.2835 41.6207 75.3381C41.6616 75.3926 41.6818 75.4599 41.6776 75.528C41.6735 75.596 41.6453 75.6604 41.5981 75.7096C39.972 76.9348 39.9342 77.6912 40.6451 79.2114L40.8115 79.5593C41.704 81.4275 43.368 84.8991 41.6737 87.811C41.6481 87.854 41.6114 87.8892 41.5675 87.9132C41.5236 87.9371 41.4741 87.9489 41.4241 87.9472V87.9472Z" fill="black" />
        <defs>
          <linearGradient id="paint0_linear_102_412" x1="125.5" y1="0" x2="156" y2="173.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#B272E4" />
            <stop offset="1" stop-color="#B272E4" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>


    </div>
  )
}

export default ArticleCardOne
