import React from 'react'
import {Box, Typography} from '@mui/material'
import ContentTooltip from '../../../components/shared/Tooltips/ContentTooltip'

type Props = {
  description: string
}

const WebsiteDescription = (props: Props) => {
  const {description} = props
  return (
    <Box sx={{textOverflow: "ellipsis"}} overflow="hidden" className="cursor-pointer">
      {/* <Typography
        variant="subtitle1"
        component="div"
        color="text.secondary"
        sx={{display: 'inline'}}
      >
        {description.slice(0, 36)}
      </Typography> */}
      <ContentTooltip fullcontent={description} position="right">
        <Typography
          variant="subtitle1"
          component="p"
          color="text.secondary"
          // sx={{display: 'inline-block'}}
          className='text-line-truncate-2'
        >
          {description?.length < 40?<div>{description}<br/><br/></div>:description}
        </Typography>
      </ContentTooltip>
      
    </Box>
  )
}

export default WebsiteDescription
