import React, { useState } from 'react'
import { Button, Box, Container, Typography, TextField, MenuItem } from '@mui/material';
import { CardsContainerTrends, PageBreadcrumbs } from '../components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTENT_TRENDS } from '../constants/constants';
import useTrendsVideoContentstore from '../hooks/useTrendsVideoContentstore';
import { sleep } from '../utils/util';

type Props = {}

const Trends = (props: Props) => {
    const [relaycursor, setRelaycursor] = useState("");
    const [termid, setTermid] = useState(null)
    const { loading, contentdata, searchterms, searchid, fetchTrendsMutation, likeordislike, error, statusmsg, isopen, hasMore, setIsopen } = useTrendsVideoContentstore(relaycursor, {termid})
    const formik = useFormik({
        initialValues: {
            keyword: '',
            count: 50,
        },
        validationSchema: Yup.object({
            keyword: Yup.string().required('Required'),
            count: Yup.number().integer()
                .max(200, 'Must be 200 or less')
                // .matches(
                //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                // )
                .required('Required')
        }),

        onSubmit: async (values) => {
            alert(JSON.stringify(values, null, 2));
            await fetchTrendsMutation({keyword:values.keyword,count:values.count})
            console.log(searchid)
            // sleep(3000).then(() => formik.setSubmitting(false));     

        },
    });
    return (
        <div className="relative">
            <Box sx={{ my: 4, mx: { sx: 3, sm: 4 } }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                    <Box>
                        <Typography variant="body1" component="h3" color="grey.600">
                            Trends
                        </Typography>
                    </Box>
                    <Box>
                        <PageBreadcrumbs />
                    </Box>
                </Box>
                <Box sx={{ padding: "1rem", backgroundColor: "white" }} borderRadius={3}>
                    <form onSubmit={formik.handleSubmit}>
                        <Box display="flex" justifyContent="start" alignItems="center" columnGap={5}>
                            <TextField
                                helperText={formik.errors?.keyword || ''}
                                id="keyword-field"
                                label="Keyword"
                                name="keyword"
                                fullWidth
                                autoFocus
                                onChange={formik.handleChange}
                                value={formik.values.keyword}
                                error={Boolean(formik.touched.keyword && formik.errors.keyword) || false}
                            />
                            <TextField
                                helperText={formik.errors?.count || ''}
                                id="count-field"
                                label="Max count"
                                name="count"
                                select
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.count}
                                error={Boolean(formik.touched.count && formik.errors.count) || false}
                            >
                                <MenuItem key={50} value={50}>
                                    50
                                </MenuItem>
                                <MenuItem key={100} value={100}>
                                    100
                                </MenuItem>
                                <MenuItem key={150} value={150}>
                                    150
                                </MenuItem>
                                <MenuItem key={200} value={200}>
                                    200
                                </MenuItem>
                            </TextField>
                            <Button variant="contained" color="primary" type="submit">Search</Button>
                        </Box>

                    </form>
                </Box>
                <CardsContainerTrends data={contentdata} category={CONTENT_TRENDS} likeactions={() => console.log("liked")} hasMore={hasMore} loadactions={() => {
                    window.scrollBy(0, 10);
                    setRelaycursor(contentdata.at(-1).cursor)
                }} isloading={loading} />
            </Box>
            <Box sx={{ my: 4, mx: { sx: 3, sm: 4 } }}>
                <Typography variant="body1" component="h3" color="grey.600" sx={{my:2}}>
                    Search history:
                </Typography>
                {searchterms?.map((val, termindx) => {
                    return <Button onClick={()=>{sleep(100).then(() => {
                        setTermid(val.node.id)  
                    }).then(() => setRelaycursor(""))}} color="primary" variant='outlined' key={termindx} sx={{textTransform:"none", mr:1, my:1}}>{val.node.keyword}
                        
                        {/* {(val.node.createdAt.split(".")[0]).split("T")[0].split("-")} */}
                        {/* {formatDistanceToNow(new Date((val.node.createdAt.split(".")[0]).split("T")[0].split("-")))} */}
                        </Button>
                })}
            </Box>
        </div>
    )
}

export default Trends