/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Button, Box, Container, Typography} from '@mui/material'
import {
  amber,
  deepPurple,
  green,
  purple,
  teal,
  blue,
} from '@mui/material/colors'
import React, {useState} from 'react'
import {CardsContainer, Modal, NavBar, PageBreadcrumbs} from '../components'
import {content} from '../utils/images'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'

const Home = () => {
  const matches = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = useState(false)
  return (
    <div className="relative">
      <Box sx={{my: 4, mx: {sx: 3, sm: 4}}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body1" component="h3" color="grey.600">
              Articles
            </Typography>
          </Box>
          <Box>
            <PageBreadcrumbs />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
        >
          <Box>
            <Modal isopen={open} modalHandle={() => setOpen(!open)} />
          </Box>
          <Box
            onClick={() => setOpen(!open)}
            boxShadow={3}
            sx={{
              cursor: 'pointer',
              position: 'fixed',
              right: 10,
              bottom: 10,
              backgroundColor: teal['A400'],
              width: 50,
              height: 50,
              borderRadius: '100%',
              '&:hover': {backgroundColor: teal[400]},
              zIndex: 10,
            }}
            display="flex"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <FilterAltOutlinedIcon sx={{color: 'white'}} />
          </Box>
        </Box>
        <CardsContainer />
      </Box>
    </div>
  )
}

export default Home
