import React from 'react';

type Props = {}

const RegisterSuccess = (props: Props) => {
  return (
    <div>RegisterSuccess</div>
  )
}

export default RegisterSuccess;