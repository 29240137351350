import React from 'react'
import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import NumericLabel from 'react-pretty-numbers';
import { CONTENT_TRENDS, CONTENT_VIDEOS, TEXT_CONTENT_TYPES } from '../../../constants/constants'

type Props = { date?: any, views?: any, kind?: any, rank?: any }
export const option = {
  currency: false, commafy: true, shortFormat: true, justification: 'L', precision: 1
};
const DatenViews = (props: Props) => {
  const { date, views, kind, rank } = props;

  return (
    <Typography component="div" variant="subtitle2">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mt={2}
      >
        <Box display="flex" justifyContent={kind === CONTENT_TRENDS ? "space-between" : "start"} alignItems="center" gap={1}>
          <AccessTimeIcon
            fontSize="small"
            sx={{ color: 'defaultlight.darkermd' }}
          />
          <Typography
            variant="body2"
            component="h5"
            color="defaultlight.darkxl"
            sx={{
              ':hover': {
                color: 'secondary.main',
              },
            }}
            noWrap
          >
            {/* {format(date.split(("+"))[0] || new Date(), 'yyyy/MM/ddTHH:MM:SS')} */}
            {kind === CONTENT_TRENDS ? date.split(("T"))[0] : date.split(("+"))[0]}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          alignItems="center"
          gap={1}
          sx={{ maxWidth: '50%' }}
        >
          {kind === CONTENT_TRENDS ? <AutoGraphOutlinedIcon
            fontSize="small"
            sx={{ color: 'defaultlight.darkermd' }}
          /> : !TEXT_CONTENT_TYPES.includes(kind) && <VisibilityOutlinedIcon
            fontSize="small"
            sx={{ color: 'defaultlight.darkermd' }}
          />}
          {kind === CONTENT_TRENDS ? <Box sx={{ 
            borderBottom: rank === "Below Average" ? "2px solid red" :
            rank === "Average"? "2px solid pink" : rank === "Normal" ? "2px solid yellow":"2px solid green" }}>{rank}</Box> : !TEXT_CONTENT_TYPES.includes(kind) && <Typography
            variant="body2"
            component="h5"
            color="defaultlight.darkxl"
            sx={{
              ':hover': {
                color: 'primary.main',
              },
            }}
            noWrap
          >
            {parseFloat(views) < 1000 ? parseFloat(views) : <NumericLabel params={option}>{views}</NumericLabel>}
          </Typography>}
        </Box>
      </Box>
    </Typography>
  )
}

export default DatenViews
