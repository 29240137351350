import { contentLibraryQuery, videoLibraryQuery } from "../api/graphql/Queries";
import { fetchQuery } from "../api/ContentQueries";
import { useState, useEffect } from "react";
import { MutateMutation } from "../api/ContentMutations";
import { ContentAddorUpdateMutation } from "../api/graphql/Mutations";

export default function useContentstorelibrary(relaycursor, filterobject) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [isopen, setIsopen] = useState(false)
    const [statusmsg, setStatusmsg] = useState("")
    const [hasMoreArticles, setHasMoreArticles] = useState(false)
    const [hasMoreTools, setHasMoreTools] = useState(false)
    const [hasMoreVideos, setHasMoreVideos] = useState(false)
    const [articles, setArticles] = useState<any>([])
    const [tools, setTools] = useState<any>([])
    const [videos, setVideos] = useState<any>([])

    const fetchArticles = (filterinput) => {
        setLoading(true)
        setError(false)
        try {
            fetchQuery(contentLibraryQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { contentstoreLibraryPagination: { edges, pageInfo } } = data;
                    setHasMoreArticles(pageInfo.hasNextPage)
                    setArticles((prevContentdata) => [...prevContentdata, ...edges])                    
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setHasMoreArticles(false)
                setLoading(false)

            })

        } catch {
            console.log("rejection error")
            setError(true)
            setHasMoreArticles(false)
            setLoading(false)
        }
    }
    const fetchTools = (filterinput) => {
        setLoading(true)
        setError(false)
        try {
            fetchQuery(contentLibraryQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { contentstoreLibraryPagination: { edges, pageInfo } } = data;
                    setHasMoreTools(pageInfo.hasNextPage)
                    setTools((prevContentdata) => [...prevContentdata, ...edges])
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setLoading(false)
                setHasMoreTools(false)

            })

        } catch {
            console.log("rejection error")
            setError(true)
            setHasMoreTools(false)
            setLoading(false)
        }
    }

    const fetchVideos = (filterinput) => {
        setLoading(true)
        setError(false)
        try {
            fetchQuery(videoLibraryQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { videoContentstoreLibraryPagination: { edges, pageInfo } } = data;
                    setHasMoreVideos(pageInfo.hasNextPage)
                    setVideos((prevVideoContentdata) => [...prevVideoContentdata, ...edges])                                       
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setHasMoreVideos(false)
                setLoading(false)

            })

        } catch {
            console.log("rejection error")
            setError(true)
            setHasMoreVideos(false)
            setLoading(false)
        }
    }

    useEffect(() => {

        const filterinput = {
            first: 8,
            after: relaycursor,
            kind: 1
            // ...filterobject
        }

        fetchArticles(filterinput)
        return () => {
            setArticles([])
        }
    }, [])

    useEffect(() => {
        const filterinput = {
            first: 8,
            after: relaycursor,
            kind: 2
            // ...filterobject
        }
        fetchTools(filterinput)
        fetchVideos({
            first: 8,
            after: relaycursor,
            kind: 1
            // ...filterobject
        })

        return () => {
            setTools([])
        }
    }, [])

    const likeordislike = async (data: any) => {

        try {
            await MutateMutation(ContentAddorUpdateMutation, data).then((data) => {
                if (data !== 400) {
                    const { libraryGroupAction: { Status } } = data;
                    console.log(Status)
                }
            }).catch((e) => {
                setError(true)

            })

        } catch {
            console.log("rejection error")
        }

    }

    return { loading, error, articles, tools, videos, fetchArticles, fetchTools, fetchVideos, likeordislike, isopen, setIsopen, statusmsg, hasMoreArticles, hasMoreTools, hasMoreVideos }
}