import {Box, Typography, Button} from '@mui/material'
import React from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

type Props = {}

const AuthLayout: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  return (
    <div className="relative">
      <Outlet />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'grey.50',
        }}
      >
        <p>all rights are reserved</p>
        <Button
          variant="text"
          onClick={() => {
            navigate('/')
          }}
        >
          Home
        </Button>
        <Typography component="h6" variant="body2">
          DND_v_2022.10.1.0 (Beta)
        </Typography>
      </Box>
      <div className="authbubble bigbubblebounce" style={{bottom: 65}} />
      <div className="authbubble smallbubblebounce" style={{bottom: 45}} />
      <div className="authbubble bigbubblebouncetop" />
      <div className="authbubble smallbubblebouncetop" />
    </div>
  )
}

export default AuthLayout
