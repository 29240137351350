import React from 'react'
import {Box, Button, Grid, Typography} from '@mui/material'
import {PageBreadcrumbs} from '../components'
const PlanHistory = () => {
  return (
    <Box className="relative" mx={12}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body1" component="h3" color="grey.600">
            Plan
          </Typography>
        </Box>
        <Box>
          <PageBreadcrumbs />
        </Box>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                borderRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                py: 2,
                px: 0,
                borderBottom: 1,
                borderColor: 'grey.400',
              }}
            >
              <Typography component="h2" variant="h6" sx={{px: 3}}>
                Manage Plan
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                py: 2,
                px: 0,
              }}
            >
              <Typography
                component="h4"
                variant="body2"
                sx={{px: 3, fontWeight: 'bold'}}
              >
                Current Plan (Basic)
              </Typography>
              <Typography
                component="h4"
                variant="body2"
                sx={{px: 3, fontWeight: 'bold'}}
              >
                19$
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                borderRadius: 5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                py: 2,
                px: 0,
              }}
            >
              <Typography
                component="h4"
                variant="body2"
                sx={{px: 3, fontWeight: 'bold'}}
                color="primary"
              >
                Next Invoice date
              </Typography>
              <Typography
                component="h4"
                variant="body2"
                sx={{px: 3, fontWeight: 'bold'}}
                color="primary"
              >
                Oct 27, 2022
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                borderRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                py: 2,
                px: 0,
                borderBottom: 1,
                borderColor: 'grey.400',
              }}
            >
              <Typography component="h2" variant="h6" sx={{px: 3}}>
                Payment Method
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                borderRadius: 0,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                py: 2,
                px: 3,
              }}
            >
              <Typography component="h2" variant="body2">
                **** **** **** 1234
              </Typography>
              <Button color="primary" variant="contained">
                Update payment Method
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                mx: {md: 26, sm: 12, xs: 1},
                borderRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                py: 2,
                px: 0,
                borderBottom: 1,
                borderColor: 'grey.400',
              }}
            >
              <Typography component="h2" variant="h6" sx={{px: 3}}>
                Invoice History
              </Typography>
            </Box>
            <Box
              sx={{
                borderRadius: 5,
                borderBottomLeftRadius: 20,
                backgroundColor: 'white',
                borderBottomRightRadius: 20,
                pb: 2,
                mx: {md: 26, sm: 12, xs: 1},
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{backgroundColor: 'white', py: 2, px: 3}}
              >
                <Typography>July 27, 2021</Typography>
                <Typography>$1660.00</Typography>
                <Button color="primary" variant="text">
                  Download
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{backgroundColor: 'white', py: 2, px: 3}}
              >
                <Typography>July 27, 2021</Typography>
                <Typography>$1660.00</Typography>
                <Button color="primary" variant="text">
                  Download
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{backgroundColor: 'white', py: 2, px: 3}}
              >
                <Typography>July 27, 2021</Typography>
                <Typography>$1660.00</Typography>
                <Button color="primary" variant="text">
                  Download
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                sx={{backgroundColor: 'white', py: 2, px: 3}}
              >
                <Typography>July 27, 2021</Typography>
                <Typography>$1660.00</Typography>
                <Button color="primary" variant="text">
                  Download
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PlanHistory
