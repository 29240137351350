import React, {useState} from 'react'
import {
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Link,
  Grow,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {useNavigate} from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { authRegister } from '../api/AuthMutations';
import { AlertToast } from '../components'
import { ALERT_ERROR } from '../constants/constants'
import { useMutation } from '@tanstack/react-query'

const Register = () => {
  const navigate = useNavigate()
  const { mutate: RegisterMutation, data, isPending:isLoading, isSuccess } = useMutation({mutationKey:["register"], mutationFn:authRegister})
  const [registerError, setRegisterError] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState<string>("")
  // Formik configuration
  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Required')
    }),

    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      RegisterMutation(values, {
        onSuccess: ({ data }) => {
          if ("errors" in data) {
            setRegisterError(true);
            const isCredError = data.errors[0].message.match("already used");
            if (isCredError) {
              setErrormsg("These credentials are already used! try with new credentials!");
            } else if (data.errors[0].message.match("email format!")){
              setErrormsg("Please enter a valid email! try with new credentials!");
            } else {
              setErrormsg("Something went wrong! try with new credentials!");
            }           
          } else {
           navigate("/register/success")
          }
        }
      })

    },
  });
  return (
    <div className="relative" style={{width: '100%', overflow: 'hidden'}}>
      <Grow in={true}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{minHeight: '100vh'}}
          spacing={2}
        >
          <Grid item md={4} lg={4} className="relative logindotanimtrigger">
            <Box
              boxShadow={5}
              borderRadius={4}
              sx={{overflow: 'hidden', backgroundColor: 'white'}}
            >
              <Stack
                spacing={6}
                p={6}
                className="loginbox"
                sx={{overflow: 'hidden'}}
              >
                <Box
                  sx={{position: 'relative', zIndex: 3, margin: 'auto'}}
                  alignContent="center"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'white',
                      fontStyle: 'italic',
                      '&:after': {
                        content: '""',
                        width: 150,
                        height: 50,
                        position: 'absolute',
                        display: 'inline-block',
                        backgroundColor: '#aa00ff',
                        zIndex: -10,
                        transform: 'skew(-3deg)',
                        top: '-0.5rem',
                        bottom: '-0.25rem',
                        left: '-1.7rem',
                        right: '-0.25rem',
                        borderRadius: '0.3rem',
                      },
                    }}
                  >
                    Register
                  </Typography>
                </Box>
                <Box
                  component="div"
                  style={{
                    width: 20,
                    height: 20,
                    background: '#ffa726',
                    position: 'absolute',
                    right: -10,
                    top: -40,
                    borderRadius: '100%',
                    opacity: 0.8,
                    transition: 'all 2s ease-in-out',
                  }}
                  className="loginanimdot"
                />
                <Box sx={{width: '100%'}}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      helperText={formik.errors?.email || ''}
                      id="email-field"
                      label="Email"
                      name="email"
                      fullWidth
                      autoFocus
                      sx={{mt: 1}}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={Boolean(formik.touched.email && formik.errors.email) || false}
                    />
                    <TextField
                      helperText={formik.errors?.password || ''}
                      type="password"
                      name="password"
                      id="password-field"
                      label="Password"
                      fullWidth
                      sx={{
                        mt: 4,
                        // '&.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root': {
                        // fontSize:'0.5rem',}
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={Boolean(formik.touched.password && formik.errors.password) || false}
                    />
                    <LoadingButton
                      fullWidth
                      loading={isLoading}
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        mt: 4,
                        borderRadius: 1.5,
                        px: 5,
                        textTransform: 'inherit',
                      }}
                      type="submit"
                    >
                      Register account
                    </LoadingButton>
                  </form>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Link
                      component="p"
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="primary.main"
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                    >
                      Login to account?
                    </Link>
                    <Link
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="secondary.darkxl"
                      onClick={() => {
                        navigate('/auth/forgotpassword')
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grow>
      <AlertToast title={errormsg} level={ALERT_ERROR} isOpen={registerError} onClose={() => setRegisterError(false)} />
    </div>
  )
}

export default Register
