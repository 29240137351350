import { CardsContainer } from '../components'
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import useContentstorelibrary from '../hooks/useContentstorelibrary'
import { CONTENT_ARTICLES, CONTENT_TOOLS, CONTENT_VIDEOS } from '../constants/constants'

type Props = {}

const Library = (props: Props) => {
  const { loading, articles, tools, videos, fetchTools, fetchArticles, fetchVideos, likeordislike, error, statusmsg, isopen, setIsopen, hasMoreArticles, hasMoreTools, hasMoreVideos } = useContentstorelibrary("", {})
  return (
    <div style={{ position: 'relative' }}>
      <Box>
        <Typography variant="h4">Articles</Typography>
        <CardsContainer data={articles} category={CONTENT_ARTICLES} likeactions={() => console.log("liked")} hasMore={hasMoreArticles} loadactions={() => {
          window.scrollBy(0, 10);
          const filterinput = {
            first: 8,
            after: articles.at(-1).cursor,
            kind: 1
            // ...filterobject
          }
          fetchArticles(filterinput)

        }} isloading={loading} />
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h4">Tools</Typography>
        <CardsContainer data={tools} category={CONTENT_TOOLS} likeactions={() => console.log("liked")} hasMore={hasMoreTools} loadactions={() => {
          window.scrollBy(0, 10);
          const filterinput = {
            first: 8,
            after: tools.at(-1).cursor,
            kind: 2
            // ...filterobject
          }
          fetchTools(filterinput)

        }} isloading={loading} />
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h4">Videos</Typography>
        <CardsContainer data={videos} category={CONTENT_VIDEOS} likeactions={() => console.log("liked")} hasMore={hasMoreVideos} loadactions={() => {
          window.scrollBy(0, 10);
          const filterinput = {
            first: 8,
            after: videos.at(-1).cursor,
            kind: 2
            // ...filterobject
          }
          fetchVideos(filterinput)

        }} isloading={loading} />
      </Box>

      <Box
        sx={{
          position: 'fixed',
          width: '30vh',
          height: '30vh',
          right: '-2vw',
          top: '20vh',
          backgroundColor: 'primary.main',
          borderRadius: '100%',
          zIndex: -1,
          opacity: 0.3,
        }}
      >
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#FF0066"
            d="M45.8,-19.3C51.5,2.8,43,25.2,26,38.1C8.9,51.1,-16.7,54.6,-32.1,43.6C-47.6,32.5,-52.9,6.8,-45.8,-17.1C-38.8,-41,-19.4,-63.2,0.3,-63.3C20,-63.4,40,-41.4,45.8,-19.3Z"
            transform="translate(100 100)"
          />
        </svg>
      </Box>
    </div>
  )
}

export default Library
