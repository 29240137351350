import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  Stack,
  TextField,
  Link,
  Grow,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { authForgot } from '../api/AuthMutations';
import { statusDeterminer } from '../utils/util';
import { ALERT_ERROR } from '../constants/constants';
import { AlertToast } from '../components';
import { useMutation } from '@tanstack/react-query';

const Forgot = () => {
  const navigate = useNavigate()
  const [forgotError, setForgotError] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState<string>("")
  const { mutate: ForgotMutation, isPending:isLoading, isSuccess } = useMutation({mutationKey:["forgot"], mutationFn:authForgot})
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required')
    }),

    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      ForgotMutation(values, {
        onSuccess: ({ data }) => {
          if ("errors" in data) {
            setForgotError(true);
            const isCredError = data.errors[0].message.match("Email is invalid!");
            if (isCredError) {
              setErrormsg("Please provide a valid email!");
            } else {
              const msg = statusDeterminer(data.errors[0].message)
              setErrormsg(msg);
            }
          } else {            
            const { data: { forgotPassword: { user } } } = data
            if (!user.isActive) {
              setErrormsg("User account is disabled or not activated!");
              setForgotError(true);
            } else {
              navigate("success")
            }
          }
        },
        onError: () => {
          setErrormsg("NetWork error! Please try again!");
          setForgotError(true);
        }
      })
      // sleep(3000).then(() => formik.setSubmitting(false));     

    },
  });
  return (
    <div className="relative" style={{ width: '100%', overflow: 'hidden' }}>
      <Grow in={true}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: '100vh' }}
          spacing={2}
        >
          <Grid item md={4} lg={4} className="relative logindotanimtrigger">
            <Box
              boxShadow={5}
              borderRadius={4}
              sx={{ overflow: 'hidden', backgroundColor: 'white' }}
            >
              <Stack
                spacing={6}
                p={6}
                className="loginbox"
                sx={{ overflow: 'hidden' }}
              >
                <Box
                  sx={{ position: 'relative', zIndex: 3, margin: 'auto' }}
                  alignContent="center"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'white',
                      fontStyle: 'italic',
                      '&:after': {
                        content: '""',
                        width: 250,
                        height: 50,
                        position: 'absolute',
                        display: 'inline-block',
                        backgroundColor: '#aa00ff',
                        zIndex: -10,
                        transform: 'skew(-3deg)',
                        top: '-0.5rem',
                        bottom: '-0.25rem',
                        left: '-1.8rem',
                        right: '-0.25rem',
                        borderRadius: '0.3rem',
                      },
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Box>
                <Box
                  component="div"
                  style={{
                    width: 20,
                    height: 20,
                    background: '#ffa726',
                    position: 'absolute',
                    right: -10,
                    top: -40,
                    borderRadius: '100%',
                    opacity: 0.8,
                    transition: 'all 2s ease-in-out',
                  }}
                  className="loginanimdot"
                />
                <Box sx={{ width: '100%' }}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      helperText={formik.errors?.email || 'Activation link will be sent to email'}
                      id="forgot-email"
                      label="Email"
                      name="email"
                      fullWidth
                      autoFocus
                      sx={{
                        mt: 1,
                        '&.MuiFormControl-root .MuiFormHelperText-root': {
                          fontSize: '1rem',
                          color: formik.errors?.email?'error.main':'info.main',
                          ml: 0,
                        },
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={Boolean(formik.touched.email && formik.errors.email) || false}
                    />
                    <LoadingButton
                      fullWidth
                      loading={isLoading}
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        mt: 4,
                        borderRadius: 1.5,
                        px: 5,
                        textTransform: 'inherit',
                      }}
                      type="submit"
                    >
                      Request activation link
                    </LoadingButton>
                  </form>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Link
                      component="p"
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="primary.main"
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                    >
                      Login to account?
                    </Link>
                    <Link
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="secondary.darkxl"
                      onClick={() => {
                        navigate('/auth/register')
                      }}
                    >
                      Register account?
                    </Link>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grow>
      <AlertToast title={errormsg} level={ALERT_ERROR} isOpen={forgotError} onClose={() => setForgotError(false)} />
    </div>
  )
}

export default Forgot;
