import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import ModalHoc from '../../../hocs/ModalHoc'
import { Box, Container, Grid, useMediaQuery } from '@mui/material'
import Icons from '../Icons/Icons'
import ModalToolbar from '../../../components/componentsparts/Modals/ModalToolbar'
import ImageVideoContainer from '../../../components/componentsparts/Modals/ImageVideoContainer'
import ModalTitle from '../../../components/componentsparts/Modals/ModalTitle'
import ModalDescription from '../../../components/componentsparts/Modals/ModalDescription'
import { useGlobalContext } from '../../../contexts/GlobalContext'
import { CONTENT_ARTICLES, CONTENT_VIDEOS, TEXT_CONTENT_TYPES, VIDEO_CONTENT_TYPES } from '../../../constants/constants'
import Media from '../../../components/componentsparts/ImageCard/Media'
import WebsiteUrl from '../../../components/componentsparts/ImageCard/WebsiteUrl'
import DatenViews, { option } from '../../../components/componentsparts/ImageCard/DatenViews'
import { isEmpty } from '../../../utils/util';
import NumericLabel from 'react-pretty-numbers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'react-moment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const toUpperCaseFilter = (d) => {
  if (d === "a day ago") {
    return d.replace("a day ago", "1 day ago");
  } else if (d === "a month ago") {
    return d.replace("a month ago", "1 month ago");
  } else if (d === "a year ago") {
    return d.replace("a year ago", "1 year ago");
  }
  else {
    return d;
  }

};
const FullWidthModal = (props) => {
  const {
    handleGlobalState,
    globalState: { isModalOpen, contentType, currentIndex },
  }: any = useGlobalContext()
  const matches = useMediaQuery('(max-width:620px)')

  const handleClose = () => {
    handleGlobalState('isModalOpen', !isModalOpen)
  }

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <ModalToolbar handleClose={handleClose} title={currentIndex?.title || 'Title is not available'} />
        </AppBar>
        <Box sx={{ position: 'sticky', top: '10px', overflowY: 'scroll' }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box
                display="flex"
                justifyContent="start"
                flexDirection="column"
                alignItems="start"
                sx={{
                  position: 'relative',
                  m: 5,
                  gap: 5,
                  paddingBottom: '3rem',
                  overflow: 'hidden',
                }}
              >
                {TEXT_CONTENT_TYPES.includes(contentType) ? (
                  <Box>
                    <Media iswidthed={true} image={currentIndex?.images || currentIndex?.customImage} fallbackimage={currentIndex?.customImage} />
                  </Box>
                ) : (
                  <ImageVideoContainer matches={matches} url={currentIndex?.url} />
                )}

                {/* Video particulars only */}
                {contentType === CONTENT_VIDEOS && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{width:"100%"}}
                    gap={1}
                  >
                    <WebsiteUrl url={currentIndex?.channelTitle || ""} icon={currentIndex?.channelIcon} />
                    {(VIDEO_CONTENT_TYPES.includes(contentType)) && <Box display="flex" justifyContent="start" alignItems="center" gap={1}>
                      <AccessTimeIcon
                        fontSize="small"
                        sx={{ color: 'defaultlight.darkermd' }}
                        className="loaderspin"
                      />
                      <Typography
                        variant="body2"
                        component="h5"
                        color="defaultlight.darkxl"
                        sx={{
                          ':hover': {
                            color: 'secondary.main',
                          },
                        }}
                        noWrap
                      >
                        {/* {format(date.split(("+"))[0] || new Date(), 'yyyy/MM/ddTHH:MM:SS')} */}
                        <Moment filter={toUpperCaseFilter} fromNow>{currentIndex?.videopublishedat}</Moment>
                        {/* {currentIndex?.videopublishedat.split(("T"))[0]} */}
                      </Typography>
                    </Box>}
                  </Box>
                )}


                <ModalTitle
                  onClick={() => window.open(currentIndex?.url, "_blank")}
                  title={
                    currentIndex?.title || 'Title is not available'
                  }
                />

                <ModalDescription
                  description={`Description: ${currentIndex?.description || "Description is not available"}`}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              {/* Likes Section */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  my: 1,
                  mt: 5,
                  gap: 10,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{ gap: 5 }}
                >
                  {VIDEO_CONTENT_TYPES.includes(contentType) && <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ gap: 1 }}
                  >
                      <Icons
                        color="secondary"
                        animprop="default"
                        icontype="thumbup"
                      />{' '}
                      <Typography component="div" variant="body2" color="primary">
                        Video Likes :{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {parseFloat(currentIndex?.likes) < 1000 ? parseFloat(currentIndex?.likes) : <NumericLabel params={option}>{currentIndex?.likes}</NumericLabel>}
                        </span>
                      </Typography>
                  </Box>
                  }
                  
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ gap: 1 }}
                  >
                    <Icons
                      color="error"
                      animprop="default"
                      icontype="favorite"
                    />{' '}
                    <Typography component="div" variant="body2" color="error">
                      Saved :{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {parseFloat(currentIndex?.islikedCount) < 1000 ? parseFloat(currentIndex?.islikedCount) : <NumericLabel params={option}>{currentIndex?.islikedCount}</NumericLabel>}
                      </span>
                    </Typography>
                  </Box>
                  {/* Views Section */}
                  {(VIDEO_CONTENT_TYPES.includes(contentType) && !isEmpty(currentIndex.videopublishedat)) && <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    sx={{ gap: 1 }}
                  >
                    <Icons
                      color="success"
                      animprop="default"
                      icontype="views"
                    />{' '}
                    <Typography
                      component="div"
                      variant="body2"
                      color="success.main"
                    >
                      Video Views :{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {parseFloat(currentIndex?.views) < 1000 ? parseFloat(currentIndex?.views) : <NumericLabel params={option}>{currentIndex?.views}</NumericLabel>}
                        {/* <DatenViews date={currentIndex?.videopublishedat || new Date()} views={currentIndex?.views} kind={contentType} rank={currentIndex?.rank} /> */}
                      </span>
                    </Typography>
                  </Box>}

                </Box>
              </Box>

              {/* Ad Section */}
              <Box
                border={1}
                borderColor="primary.main"
                borderRadius={1}
                sx={{ position: 'sticky', top: '40px', minHeight: '90vh' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'primary.light',
                    minHeight: '90vh',
                    position: 'relative',
                  }}
                >
                  <Box>
                    <img
                      src="https://www.reelnreel.com/wp-content/uploads/2022/06/Logo.png"
                      alt="reelnreellogo"
                      style={{ objectFit: 'cover', height: '6rem' }}
                    />
                  </Box>

                  <Typography component="h4" variant="body2" color="white">
                    Social media marketing platform
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  )
}

// const Hocwrapper = ModalHoc(FullWidthModal)
// export default Hocwrapper
export default FullWidthModal;
