import { contentQuery } from "../api/graphql/Queries";
import { fetchQuery } from "../api/ContentQueries";
import { useState, useEffect } from "react";
import { MutateMutation } from "../api/ContentMutations";
import { ContentAddorUpdateMutation } from "../api/graphql/Mutations";
import { isEmpty } from "../utils/util";

export default function useContentstore(relaycursor, filterobject) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [isopen, setIsopen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [statusmsg, setStatusmsg] = useState("")
    const [hasMore, setHasMore] = useState(false)
    const [contentdata, setContentdata] = useState<any>([])
    const [prevKeyword, setPrevKeyword] = useState<any>({})

    useEffect(() => {
      setContentdata([])
    
      return () => {
        setContentdata([])
      }
    }, [filterobject?.title])
    
    useEffect(() => {
        setLoading(true)
        setError(false)
        const filterinput = {
            first: 8,
            ...filterobject
        }
        if (!isEmpty(prevKeyword?.title)) {
            if (prevKeyword?.title === filterobject.title) {
                filterinput["after"] = relaycursor
            } else {
                filterinput["after"] = null
            }
        } else{
            filterinput["after"] = null
        }
        try {
            setPrevKeyword(filterobject)
            fetchQuery(contentQuery, filterinput).then((data) => {
                if (data !== 400) {
                    const { contentstorePagination: { edges, pageInfo } } = data;
                    setContentdata((prevContentdata) => [...prevContentdata, ...edges])
                    setHasMore(pageInfo.hasNextPage)
                }
                setLoading(false)
            }).catch((e) => {
                setError(true)
                setHasMore(false)
                setLoading(false)

            })

        } catch {
            console.log("rejection error")
            setError(true)
            setHasMore(false)
            setLoading(false)
        }



        // .then((data:any) => {
        //     console.log(data)
        //     // if (data.recruiterFilterJobs) {
        //     //     setViewjobsdata(prevJobsdata => {
        //     //         return [...prevJobsdata, ...data.recruiterFilterJobs?.edges]
        //     //     })

        //     //     if (viewjobsdata && +viewjobsdata?.length < data.recruiterFilterJobs?.totalCount) {
        //     //         setHasMore(true)
        //     //     } else {
        //     //         setHasMore(false)
        //     //     }
        //     // }
        //     setLoading(false)
        // }).catch((e) => {
        //     setError(true)

        // });

        return () => {
            console.log("unmounting useContentstore.")
        }
    }, [relaycursor, filterobject?.title])

    const likeordislike = async (data: any) => {

        try {
            await MutateMutation(ContentAddorUpdateMutation, data).then((data) => {
                if (data !== 400) {
                    const { libraryGroupAction: { Status } } = data;
                    setStatusmsg("successfully done!")
                    setIsopen(true)
                }
            }).catch((e) => {
                setError(true)

            })

        } catch {
            setStatusmsg("something went wrong!")
            setIsopen(true)
        }

    }

    return { loading, error, setError, contentdata, likeordislike, hasMore, statusmsg, success, setSuccess, isopen, setIsopen }
}