import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import InboxIcon from '@mui/icons-material/Inbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import {purple} from '@mui/material/colors'
import PersonIcon from '@mui/icons-material/Person'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import {rootCertificates} from 'tls'
type Props = {
  changestep: (number: number) => void
  currentstep: number
}
const ListBox: React.FC<Props> = (props) => {
  const {changestep, currentstep} = props
  return (
    <>
      <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: -100,
            zIndex: -2,
            width: 200,
            height: 290,
          }}
        >
          <svg
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
            style={{transform: 'rotate(45deg)'}}
            className="settingsscale"
          >
            <path
              fill="#ffeb3b"
              opacity="1"
              d="M23.1,-41.3C27.5,-33.1,27,-22.6,24.9,-15C22.8,-7.3,19.1,-2.6,21.8,5.6C24.5,13.8,33.7,25.5,35.1,38.7C36.5,52,30.3,66.9,20.4,70.2C10.5,73.5,-3.1,65.1,-13.5,57C-23.9,48.9,-31.2,41.1,-38.8,33.1C-46.4,25.2,-54.5,17.1,-51.5,10C-48.6,2.8,-34.6,-3.3,-27.1,-9.3C-19.5,-15.2,-18.3,-21,-14.9,-29.6C-11.4,-38.2,-5.7,-49.6,1.8,-52.4C9.3,-55.3,18.7,-49.4,23.1,-41.3Z"
              transform="translate(100 100)"
            />
          </svg>
        </Box>
        <nav aria-label="main mailbox folders" className="relative">
          <List sx={{zIndex: 10}}>
            <ListItem disablePadding onClick={() => changestep(1)}>
              <ListItemButton
                sx={{
                  backgroundColor: currentstep === 1 ? purple[100] : 'white',
                  ':hover': {
                    backgroundColor: purple[50],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      color: purple[900],
                    },
                  },
                  ':focus': {
                    backgroundColor: purple[100],
                    color: purple[900],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <PersonIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Profile"
                  primaryTypographyProps={{fontSize: 14}}
                />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => changestep(2)} disablePadding>
              <ListItemButton
                sx={{
                  backgroundColor: currentstep === 2 ? purple[100] : 'white',
                  ':hover': {
                    backgroundColor: purple[50],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      color: purple[900],
                    },
                  },
                  ':focus': {
                    backgroundColor: purple[100],
                    color: purple[900],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Change password"
                  primaryTypographyProps={{fontSize: 14}}
                />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => changestep(3)} disablePadding>
              <ListItemButton
                sx={{
                  backgroundColor: currentstep === 3 ? purple[100] : 'white',
                  ':hover': {
                    backgroundColor: purple[50],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      color: purple[900],
                    },
                  },
                  ':focus': {
                    backgroundColor: purple[100],
                    color: purple[900],
                    '&.MuiButtonBase-root .MuiListItemText-root span': {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <MarkEmailUnreadIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary="Contact us"
                  primaryTypographyProps={{
                    fontSize: 14,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </>
  )
}

export default React.memo(ListBox)
