import React from 'react'

type Props = {matches: boolean, url?:string}

const ImageVideoContainer = (props: Props) => {
  const {matches, url} = props
  return (
    <iframe
      style={{width: `${matches ? '80vw' : '100%'}`, height: '50vh'}}
      src={`https://www.youtube-nocookie.com/embed/${url?.split("?v=")[1]}`||"https://www.youtube-nocookie.com/embed/Bfmv8nyXW3M"}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )
}

export default ImageVideoContainer
