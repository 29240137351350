import React from 'react'
import { Typography } from '@mui/material'

type Props = { title: string, onClick?: any }

const ModalTitle = (props: Props) => {
  const { title, ...rest } = props
  return (
    <div className="cursor-pointer" title="Opens in new tab" {...rest}>
      <Typography component="h1" variant="subtitle1" color="primary">
        {title}
      </Typography>
    </div>

  )
}

export default ModalTitle
