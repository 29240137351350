import React from 'react'
import {Box, useMediaQuery} from '@mui/material'
import {NavBar} from '../components'
import {Outlet} from 'react-router-dom'
import {teal} from '@mui/material/colors'

const DefaultLayout = () => {
  const matches = useMediaQuery('(min-width:600px)')
  return (
    <div className="relative">
      <Box sx={{boxShadow: 'none'}}>
        <NavBar matches={matches} />
        <Box sx={{my: 4, mx: {sx: 3, sm: 4}}}>
          <div>
            <Outlet />
          </div>
        </Box>
      </Box>
      {matches && (
        <>
          <Box
            className="bigbubble light"
            style={{
              position: 'fixed',
              backgroundColor: teal[100],
              left: '60%',
              top: 0,
              width: 600,
              height: 600,
              zIndex: -1,
              borderRadius: '100%',
              opacity: 0.3,
            }}
          />
          <Box
            className="bigbubble light"
            style={{
              position: 'fixed',
              left: 20,
              bottom: 38,
              width: 800,
              height: 800,
              zIndex: -1,
              borderRadius: '100%',
              opacity: 0.3,
            }}
          />{' '}
        </>
      )}
      <div
        className="authbubble smallbubblebounce"
        style={{position: 'fixed'}}
      />
      <div className="authbubble bigbubblebounce" style={{position: 'fixed'}} />
    </div>
  )
}

export default DefaultLayout
