import React from 'react'

type Props = {}

const ForgotSuccess = (props: Props) => {
  return (
    <div>ForgotSuccess</div>
  )
}

export default ForgotSuccess