import React, {FC, useState, SyntheticEvent} from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

type Props = {
  title: string;
  level: any;
  onClose: (value:boolean) => void;
  isOpen: boolean;
}
const AlertToast: FC<Props> = (props) => {
  const {title, level, onClose, isOpen} = props

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    onClose(false)
  }

  return (
    <Stack spacing={2} sx={{width: '100%'}}>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={level || 'success'}
          sx={{width: '100%'}}
        >
          {title}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default AlertToast;
