import React from 'react'
import { Grid, Stack, Box, Typography, Button } from '@mui/material'
import TrendsCard from './componentsparts/TrendsCard'
import AlertToast from './shared/AlertToasts/AlertToast'
import FullWidthModal from './shared/Modals/FullWidthModal'
import { useGlobalContext } from '../contexts/GlobalContext'
import { ALERT_ERROR, VIDEO_STAGES } from '../constants/constants'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { noresultsfound } from '../utils/images';

const CardsContainerTrends = (props: any) => {
  const { category, data, likeactions, loadactions, isloading, hasMore } = props
  const {
    globalState: { isModalOpen },
  }: any = useGlobalContext()

  return (
    <>{VIDEO_STAGES.map((stageval, stageindx) => {
      const filteredbyRank = data.filter(checkstagewith => checkstagewith.node.rank === stageval)
      return <Box key={stageindx}>
        {(filteredbyRank && filteredbyRank.length > 0) && <Typography variant="subtitle1" sx={{mt:3, mb:2,
        position:"relative",
        textDecoration:"underline",
        "&::before":{
          content:'""',
          width:20,
          height:20,
          position:"absolute",
          top:-13,
          left:-10,
          backgroundColor:"red",
          borderRadius:50,
          opacity:0.3,
          animation: "ping 4s ease-in-out infinite !important"
        }}}>{stageval}</Typography>}
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{ my: { sx: 3, md: 2 } }}
            rowGap={5}
          >
            {/* Array.from(Array(16)) */}
            
            {filteredbyRank?.map((val, index) => {              
                return (
                  <Grid item lg={3} md={4} sm={12} xs={12} key={index}>
                    <TrendsCard category={category} item={val.node} id={val.node.id} title={val.node.title} description={val.node.description} url={val.node.url} publishedDate={val.node.videopublishedat}
                      channelIcon={val.node.channelIcon} channelTitle={val.node.channelTitle} images={val.node.image} customImage={val.node.customImage} likeactions={likeactions} tags={val.node.tags} isliked={val.node.isliked} islikedCount={val.node.islikedCount} views={val.node.views}
                      rank={val.node.rank}
                    />
                  </Grid>
                )              
            })}
          </Grid>
      </Box>
    })}


      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 3 }} className="loadmoreloader">
        {isloading && <Button color="primary" variant="contained" className="loaderspin" sx={{ p: 3, borderRadius: 100, border: "12px solid white", borderStyle: "outset" }} >
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </Button>}
        {(!isloading && hasMore) && <Button color="primary" variant="contained" size="large" sx={{ textTransform: 'capitalize', m: 3 }}
          onClick={loadactions}>
          <ExpandCircleDownIcon sx={{ mx: 1 }} />Load more..
        </Button>}
        {(!hasMore && !isloading) && <Box textAlign="center" sx={{height:"100%"}}><img src={noresultsfound} alt="no found" style={{borderRadius:2, width:"100%", height:"auto"}} className="responsive-image-notfound" /><Typography variant="h5">No more results found</Typography></Box>}
      </Box>

      {/* <AlertToast title="great for everhting!" level="success" /> */}

      <FullWidthModal />
    </>
  )
}

export default CardsContainerTrends;
