import React, { useState } from 'react'
import { Box, Typography, TextField, Stack, useMediaQuery, Button, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Icons from './shared/Icons/Icons'
import { Link } from 'react-router-dom'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestPostApi } from '../api/RestApiCalls';
import { ALERT_ERROR, ALERT_SUCCESS, URL_END_POINTS } from '../constants/constants';
import AlertToast from './shared/AlertToasts/AlertToast'

type Props = {}

const Report = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [isopen, setIsopen] = useState(false)
  const [error, setError] = useState(false);
  const matches = useMediaQuery('(min-width:600px)')
  const [reportinitialdata, setReportinitialdata] = useState({
    email:'',
    url:''
  })
  const formik = useFormik({
    initialValues: reportinitialdata,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please enter url'),
      url: Yup.string().required('Required'),
    }),
    enableReinitialize: true,
    onSubmit: async(values) => {
      // alert(JSON.stringify(values, null, 2));
      setLoading(true);
      const form_data = new FormData();
      form_data.append("email",values.email);
      form_data.append("url", values.url)
      await requestPostApi(URL_END_POINTS.report, form_data);
      setLoading(false);
      setIsopen(true)
      setError(false)
    },
  });
  return (
    <>
      <Box
        sx={{ borderBottom: 0.3, borderColor: 'grey.300' }}
        py={3}
        px={3}
        className="relative fadein"
        display="flex"
        alignItems="center"
      >
        <Typography variant="h5" component="h3" fontWeight="bold">
          Report an issue
        </Typography>

        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/"
          sx={{ textTransform: 'none', ml: 'auto' }}
        >
          Home
        </Button>

      </Box>
      <Grid container>
        <Grid item xs={12} lg={9} md={9}>
          <Box p={3} className="fadein" sx={{ border: '1px solid lightgrey', height:'100%', borderRadius: 4, my:3, mx:4, p:3 }}>
            <Box className="relative" sx={{mt:6}}>
              <form onSubmit={formik.handleSubmit}>
                <Stack direction="column" gap={4}>
                  <TextField
                    helperText="Enter url to remove from the website"
                    id="demo-helper-text-aligned"
                    label="Report url"
                    name="url"
                    autoFocus
                    sx={{ mt: 1, width: '100%' }}
                    onChange={formik.handleChange}
                    value={formik.values.url}
                    error={Boolean(formik.touched.url && formik.errors.url) || false}
                  />
                  <TextField
                    helperText="Please enter the reference email"
                    id="demo-helper-text-aligned"
                    label="Enter email"
                    name="email"
                    autoFocus
                    sx={{ mt: 1, width: '100%' }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={Boolean(formik.touched.email && formik.errors.email) || false}
                  />
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    color="primary"
                    variant="contained"
                    size="large"
                    sx={{ textTransform: 'inherit', px: 8, width: '50%' }}
                  >
                    {loading ? 'Please wait...' : 'Submit'}
                  </LoadingButton>

                  <Box display="flex" alignItems="center" gap={1}>
                  <LoginOutlinedIcon /><Typography component={Link} to="/auth/login">Login</Typography>
                  </Box>
                </Stack>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} md={3}>
          {matches && (
            <Box
              sx={{display:'flex', alignItems:'center', justifyContent:'center',height:'100%'}}
            >
              <Icons
                color="secondary"
                animprop="default"
                icontype="Userforcontact"
                animtype="noanim"
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <AlertToast title="Success" level={error?ALERT_ERROR:ALERT_SUCCESS} isOpen={isopen} onClose={() => setIsopen(false)} />
    </>
  )
}

export default Report
