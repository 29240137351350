import React, {useRef, useState} from 'react'
import {
  Box,
  Typography,
  Divider,
  Avatar,
  Button,
  TextField,
  Stack,
  useMediaQuery,
} from '@mui/material'
import {defaultuser} from '../utils/images'
import {ImageFileReader} from '../api/UploadFile'
import {LoadingButton} from '@mui/lab'
import Icons from './shared/Icons/Icons'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ALERT_ERROR, ALERT_SUCCESS, UPLOADFILEKIND } from '../constants/constants'
import { MutateMutation } from '../api/ContentMutations'
import { FileUploadMutation, UpdateUserinfobyUserMutation } from '../api/graphql/Mutations'
import AlertToast from './shared/AlertToasts/AlertToast'

type Props = {}

const Profile = (props: Props) => {
  const [statusmsg, setStatusmsg] = useState<string>("")
  const [isopen, setIsopen] = useState(false)
  const [error, setError] = useState(false)
  const matches = useMediaQuery('(min-width:600px)')
  const profilefileref = useRef<HTMLInputElement>(null)
  const [isImagepreview, setIsImagepreview] = useState(null)
  const [loading, setLoading] = useState(false)
  const [authdata, setAuthdata] = useState<any>(() => {
    if(localStorage.getItem("authdata")){
      const authtoparse:any = localStorage?.getItem("authdata") || "{}";
      return JSON.parse(authtoparse);
    } else {
      return null
    }
  });
  const UploadPic = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    setLoading(true)
    const fileData = e.target.files[0]
    let fileinput = {
      kind: (UPLOADFILEKIND.PROFILE_PICTURE).toString(),
      name: fileData.name,
      mimetype: fileData.type,
      profilepic:null

    }
    await ImageFileReader(fileData)
      .then((basedata: any) => {
        setIsImagepreview(basedata)
        setLoading(false)
        return basedata
      })
      .then(async (previeData) => {
        // const respdata = S3fileUploadFinish(previeData);
        fileinput.profilepic = previeData;
        const respdata:any = await MutateMutation(FileUploadMutation, fileinput);
        if (respdata !== 400){
          console.log(respdata);
        }
        
      })
      .catch((err) => {
        setLoading(false)
        // eslint-disable-next-line no-console
        console.log('file resp error', err.message)
      })
  }
  const handleProfiletrigger = () => {
    if (profilefileref) {
      profilefileref?.current?.click()
    }
  }

  const formik = useFormik({
    initialValues: {
      name: authdata?.fullName||"",
      email: authdata?.email||"",
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      name: Yup.string()
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        // )
        .required('Required')
    }),

    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));    
        const respformdata:any = MutateMutation(UpdateUserinfobyUserMutation,{email:values.email, fullName: values.name})
        if ( respformdata !== 400){
          setStatusmsg("Success!");
          setError(false)
          setOpen(true)
        } else {
          setStatusmsg("Something went wrong!");
          setError(true)
          setOpen(true)
        }
        // sleep(3000).then(() => formik.setSubmitting(false));   

    },
  });
  return (
    <>
      <Box
        sx={{borderBottom: 0.3, borderColor: 'grey.300'}}
        py={3}
        px={3}
        className="relative fadein"
      >
        <Typography variant="h5" component="h3" fontWeight="bold">
          Profile
        </Typography>
        {/* <div style={{}}> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{position: 'absolute', top: -110, right: 0}}
          className="settingstopwave"
        >
          <path
            fill="#4db6ac"
            fillOpacity="1"
            d="M0,96L80,128C160,160,320,224,480,240C640,256,800,224,960,176C1120,128,1280,64,1360,32L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
        {/* </div> */}
      </Box>
      <Box mt={3} p={3} className="fadein">
        <Box
          display="flex"
          alignContent="center"
          alignItems="center"
          justifyContent="start"
          gap={5}
        >
          <Avatar
            alt="Remy Sharp"
            src={isImagepreview || authdata?.profilePic || defaultuser}
            sx={{width: 90, height: 90}}
          />
          <input
            type="file"
            ref={profilefileref}
            style={{display: 'none'}}
            name="profileimage"
            accept="image/*"
            onChange={UploadPic}
          />
          {/* <input type="file" ref={profilefileref} className="d-none" name="profileimage" accept="image/*" onChange={(e) => handleOnchangefile(e)} /> */}
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleProfiletrigger}
            sx={{textTransform: 'inherit', px: 8}}
          >
            {loading ? 'Please wait...' : 'Upload'}
          </LoadingButton>
        </Box>
        <Box className="relative">
          <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" gap={4} mt={3}>
              <TextField
                helperText={formik.errors?.name || ''}
                id="demo-helper-text-aligned"
                label="Name"
                name="name"
                fullWidth
                autoFocus
                sx={{mt: 1, maxWidth: {sm: '50%', xs: '100%'}}}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={Boolean(formik.touched.name && formik.errors.name) || false}
              />
              <TextField
                helperText={formik.errors?.email || ''}
                id="demo-helper-text-aligned"
                label="Email"
                name="email"
                autoFocus
                sx={{mt: 1, maxWidth: {sm: '50%', xs: '100%'}}}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email) || false}
                disabled
              />
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                sx={{textTransform: 'inherit', px: 8, width: '50%'}}
              >
                {loading ? 'Please wait...' : 'Update'}
              </LoadingButton>
            </Stack>
          </form>
          {matches && (
            <>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '15%',
                  right: '6rem',
                  zIndex: 2,
                }}
              >
                <Icons
                  color="secondary"
                  animprop="default"
                  icontype="yellowbucket"
                  animtype="noanim"
                />
              </div>
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '3rem',
                  width: 300,
                  zIndex: 1,
                }}
              >
                <path
                  fill="#fffde7"
                  d="M63.1,-47.3C79.2,-30.2,87.9,-4.1,82.3,18.2C76.8,40.5,57,58.9,35.1,67.2C13.3,75.5,-10.7,73.7,-27.2,63.2C-43.7,52.6,-52.8,33.4,-55.9,14.3C-59,-4.7,-56.1,-23.6,-45.8,-39.4C-35.5,-55.3,-17.7,-68.2,2.9,-70.5C23.5,-72.8,47,-64.5,63.1,-47.3Z"
                  transform="translate(100 100)"
                />
              </svg>
            </>
          )}
        </Box>
      </Box>
      <AlertToast title={statusmsg} level={error ? ALERT_ERROR:ALERT_SUCCESS} isOpen={isopen} onClose={() => setIsopen(false)} />
    </>
  )
}

export default Profile
