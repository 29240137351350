import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import {purple} from '@mui/material/colors'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import React from 'react'

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.success.main,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.success.main,
  },
}))

type Props = {
  isLiked: boolean,
  totallikes:  number,
  LikeClickHandler: () => void
}

const MediaPsedocontent = (props: Props) => {
  const {isLiked, totallikes, LikeClickHandler} = props
  return (
    <Box
      className="growwidth d-none"
      sx={{
        content: `''`,
        position: 'absolute',
        color: 'white',
        fontSize: 12,
        top: 10,
        left: 10,
        width: '9rem',
        height: '2rem',
        margin: 'auto',
        borderRadius: '1.3rem',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: purple[600],
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        px: 1,
      }}
    >
      <LightTooltip
        title={isLiked ? "Un Like":"Like"}
        arrow={true}
        sx={{
          'tooltipClasses.tooltip': {
            backgroundColor: 'red',
            color: 'rgba(0, 0, 0, 0.87)',
          },
        }}
      >
        <IconButton sx={{color: 'white'}} onClick={LikeClickHandler}>
          {!isLiked ? (
            <FavoriteBorderIcon fontSize="small" sx={{cursor: 'pointer'}} />
          ) : (
            <FavoriteIcon fontSize="small" sx={{cursor: 'pointer'}} />
          )}
        </IconButton>
      </LightTooltip>
      <Typography component="h5" sx={{px: 1}} noWrap>
        {totallikes}
      </Typography>
    </Box>
  )
}

export default MediaPsedocontent
