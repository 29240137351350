import axiosInstance from "../utils/axiosInstance"

export const fetchQuery = (gqlQuery:any, inputs:any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axiosInstance
      .post('/graphql/', {
        query: gqlQuery,
        variables: { ...inputs },
      }).then(({ data: { data, errors } }) => {
        if(errors){
            return resolve(400)
        } else {
          return resolve(data);  
        }        
      }).catch(() => {
        return resolve(400)
      });
    })
}