import React, { useEffect } from 'react'
import { CssBaseline } from '@mui/material'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthLayout, HomeLayout, DefaultLayout } from './layouts'
import {
  Forgot,
  Home,
  LandingSingle,
  Library,
  Login,
  PlanHistory,
  Pricing,
  Register,
  Reset,
  Settings,
  Tools,
  Videos,
} from './pages'
import Articles from './pages/Articles'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ForgotSuccess from './pages/StatusPages/ForgotSuccess'
import RegisterSuccess from './pages/StatusPages/RegisterSuccess'
import LoginSuccess from './pages/StatusPages/LoginSuccess'
import Trends from './pages/Trends'
import { Report } from './components'
const queryclient = new QueryClient()
const App = () => (
  <QueryClientProvider client={queryclient}>
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingSingle />} />
        <Route path="report" element={<Report />} />
        {/* <Route path="auth" element={<AuthLayout />}>
				<Route path="login" element={<Login />} />
			</Route> */}
        <Route path="auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="register/success" element={<RegisterSuccess />} />        
          <Route path="changepassword" element={<Home />} />
          <Route path="forgotpassword" element={<Forgot />} />
          <Route path="forgotpassword/success" element={<ForgotSuccess />} />
          <Route path="resetpassword/:token" element={<Reset />} />
          <Route path="gateway" element={<LoginSuccess />} />
          <Route index element={<Login />} />
        </Route>
        <Route path="user" element={<HomeLayout />}>
          <Route path="settings" element={<Settings />} />
          <Route path="plan/history" element={<PlanHistory />} />
          <Route path="library" element={<Library />} />
          <Route index element={<Settings />} />
        </Route>
        <Route path="content" element={<HomeLayout />}>
          <Route path="articles" element={<Articles />} />
          <Route path="videos" element={<Videos />} />
          <Route path="tools" element={<Tools />} />
          <Route path="trends" element={<Trends />} />
        </Route>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="pricing" element={<Pricing />} />
        </Route>
      </Routes>
      <CssBaseline />
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} position='right' />
  </QueryClientProvider>
)

export default App


