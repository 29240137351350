import React from 'react'
import {Grid, Box, Typography, Button} from '@mui/material'
import ImageCard from './componentsparts/ImageCard'
import FullWidthModal from './shared/Modals/FullWidthModal'
import {useGlobalContext} from '../contexts/GlobalContext'
import { CONTENT_VIDEOS } from '../constants/constants'
import TrendsCard from './componentsparts/TrendsCard'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { noresultsfound } from '../utils/images'

const CardsContainer = (props:any) => {
  const { category, data, likeactions, loadactions, isloading, hasMore } = props  
  const {
    globalState: {isModalOpen},
  }: any = useGlobalContext()

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        sx={{my: {sx: 3, md: 2}}}
        rowGap={5}
      >
        {/* Array.from(Array(16)) */}
        {data?.map((val, index) => (
          <Grid item lg={3} md={4} sm={12} xs={12} key={index}>
            {category === CONTENT_VIDEOS ?<TrendsCard item={val.node} category={category} id={val.node.id} title={val.node.title} description={val.node.description} url={val.node.url} publishedDate={val.node.videopublishedat}
              channelIcon={val.node.channelIcon} channelTitle={val.node.channelTitle} images={val.node.image} customImage={val.node.customImage} likeactions={likeactions} tags={val.node.tags} isliked={val.node.isliked} islikedCount={val.node.islikedCount} views={val.node.views}
              rank={val.node.rank}
            />:
            <ImageCard item={val.node} category={category} id={val.node.id} title={val.node.title} description={val.node.description} url={val.node.url} publishedDate = {val.node.publishedDate}
              images={val.node.images} customImage={val.node.customImage} likeactions={likeactions} tags={val.node.tags} isliked={val.node.isliked} islikedCount={val.node.islikedCount} />}
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 3 }} className="loadmoreloader">
        {isloading && <Button color="primary" variant="contained" className="loaderspin" sx={{p:3, borderRadius:100, border:"12px solid white", borderStyle:"outset"}} >
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </Button>}
        {(!isloading && hasMore) && <Button color="primary" variant="contained" size="large" sx={{textTransform:'capitalize', m:3}}
          onClick={loadactions}>
          <ExpandCircleDownIcon sx={{mx:1}} />Load more..
        </Button>}
        {(!hasMore && !isloading) && <Box textAlign="center"><img src={noresultsfound} alt="no found" style={{borderRadius:2, width:"100%", height:"auto"}} /><Typography variant="h5">No more results found</Typography></Box>}
      </Box>
      {/* <AlertToast title="great for everhting!" level="success" /> */}
      
      <FullWidthModal />
    </>
  )
}

export default CardsContainer;
