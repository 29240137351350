import React from 'react';
import { Box, Typography } from '@mui/material';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';


type Props = {
  url: string,
  icon?: any
}

const WebsiteUrl = (props: Props) => {
  const { url, icon } = props
  return (
    <Box display="flex" justifyContent="start" alignItems="center" gap={1} className="cursor-pointer">
      {icon ? <div style={{padding:"1rem",backgroundImage:`url(${icon})`,
      backgroundSize:"contain",
      backgroundRepeat:"no-repeat",
      width:"20px", height:"20px", borderRadius:"50%"}} />:<PublicOutlinedIcon fontSize="small" sx={{ color: 'defaultlight.darkermd', ':hover': {
        color: 'primary.main',
      }, }} />}
      <Typography
        variant="body2"
        component="h5"
        color="defaultlight.darkxl"
        sx={{
          ':hover': {
            color: 'primary.main',
          },
        }}
        noWrap
      >
        {url}
      </Typography>
    </Box>
  )
}

export default WebsiteUrl
