import {Typography, Box, Grow} from '@mui/material'
import React, {useState, useCallback} from 'react'
import {
  ChangePassword,
  ContactUs,
  ListBox,
  PageBreadcrumbs,
  Profile,
  SettingsCard,
} from '../components'
import Grid from '@mui/material/Unstable_Grid2'
import { SETTINGS_CRUMBS } from '../constants/constants';

type Props = {}

const Settings = (props: Props) => {
  const [step, setStep] = useState(1)
  const stepHandler = useCallback((value: number) => {
    setStep(value)
    console.log('called')
  }, [])
  return (
    <Grow in={true}>
      <Box className="relaitve" sx={{mx: {sm: 12, xs: 3}}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body1" component="h3" color="grey.600">
              Settings
            </Typography>
          </Box>
          <Box>
            <PageBreadcrumbs links={[
              {title:"Home",type:"link",url:"/"},
              {title:SETTINGS_CRUMBS[step],type:"text",url:"/"},
              ]} />
          </Box>
        </Box>
        <Box mt={3} className="relative">
          <Grid container spacing={6} columns={16}>
            <Grid xs={16} md={4}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  position: 'sticky',
                  top: '150px',
                }}
                boxShadow={2}
                py={2}
                borderRadius={4}
                alignContent="center"
              >
                <ListBox changestep={stepHandler} currentstep={step} />
              </Box>
            </Grid>
            <Grid xs={16} md={12}>
              <Box>
                <Box
                  sx={{backgroundColor: 'white'}}
                  boxShadow={2}
                  pb={2}
                  borderRadius={4}
                >
                  <SettingsCard>
                    {step === 1 && <Profile />}
                    {step === 2 && <ChangePassword />}
                    {step === 3 && <ContactUs />}
                  </SettingsCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grow>
  )
}

export default Settings
