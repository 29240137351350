export {default as Home} from './Home'
export {default as Login} from './Login'
export {default as Register} from './Register'
export {default as Reset} from './Reset'
export {default as Forgot} from './Forgot'
export {default as Settings} from './Settings'
export {default as Landing} from './Landing'
export {default as Pricing} from './Pricing'
export {default as PlanHistory} from './PlanHistory'
export {default as Videos} from './Videos'
export {default as Library} from './Library'
export {default as LandingSingle} from './LandingSingle'
export {default as Tools} from './Tools'
