import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export default function SettingsCard(props) {
  const {children} = props
  return (
    <Card sx={{minWidth: 275, boxShadow: 0, borderRadius: 4}}>
      <CardContent sx={{p: 0, overflow: 'hidden'}}>{children}</CardContent>
    </Card>
  )
}
