import {Typography, Box, Chip} from '@mui/material'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import React from 'react'

type Props = { tags?:any}

const TagChips = (props: Props) => {
  const { tags } = props;
  return (
    <Typography component="div" variant="subtitle2" noWrap>
      <Box
        className="chipscrollbar"
        display="flex"
        justifyContent="start"
        alignItems="center"
        gap={1}
        mt={1}
        sx={{overflowX: 'scroll'}}
      >
        <SellOutlinedIcon
          fontSize="small"
          sx={{color: 'defaultlight.darkermd'}}
        />
        {/* new Array(13).fill(0, 0, 13) */}
        {tags?.map((chipvalue, chipindx) => (
          <Chip
            key={`${chipvalue}-${chipindx}`}
            label={`${chipvalue}`}
            sx={{
              '&:hover': {
                color: 'white',
                backgroundColor: 'info.main',
              },
            }}
          />
        ))}
      </Box>
    </Typography>
  )
}

export default TagChips
